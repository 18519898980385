import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import './DesktopNavbar/DesktopNavbar.scss';
import './DesktopSidebar/DesktopSidebar.scss';
import './MobileNavbar/MobileNavbar.scss';

import { DesktopSidebar } from './DesktopSidebar/DesktopSidebar';
import { DesktopNavbar }  from './DesktopNavbar/DesktopNavbar';
import { MobileNavbar }   from './MobileNavbar/MobileNavbar';

function AdminTemplate() {
  const [displayed, setDisplayed] = useState(false);

  const toggleProfileDropdown = (element) => {
    let newState;
    const button  = document.getElementById('profile-button');
    const popup   = document.getElementById('profile-popup');

    if (button.contains(element.target)) {
      newState = !displayed;
    } else {
      newState = popup ? popup.contains(element.target) : false;
    }

    setDisplayed(newState);
  }

  return (
    <div className="Template" onClick={(e) => toggleProfileDropdown(e) }>
      <DesktopNavbar displayDropdown={ displayed } />
      <DesktopSidebar />
      <MobileNavbar />

      <div className="admin-content">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminTemplate;