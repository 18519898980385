import { Component } from 'react';

import bookdocLogo from '../assets/images/bookdocLogo.png';
import nestleLogo from '../assets/images/nestleLogo.png';
import CaringPharmacyGroupLogo from '../assets/images/CaringPharmacyGroupLogo.png';

export class Header extends Component {
  render() {
    return (
        <div className="header">
            <div className="left">
                <img src={nestleLogo} alt="nestle" />
                <img src={CaringPharmacyGroupLogo} alt="caring" className="ms-1" />
            </div>
            <div className="right">
                <a href={process.env.REACT_APP_MAIN_SITE_URL}><img src={bookdocLogo} alt="bookdoc" /></a>
            </div>
        </div>
    );
  }
}