import React, { Component } from 'react';
import { NavLink }          from 'react-router-dom';
import { SidebarLinks }     from './SidebarLinks';
import bookdocLogo from './../../../assets/images/bookdocLogo.png';
import moment from 'moment';

export class DesktopSidebar extends Component {
  render() {
    return (
      <div id="desktop-sidebar" className="d-lg-block d-none">
        <div className="menu-logo-div bg-white text-center">
          <NavLink exact="true" to="/admin" className="h-100" >
            <img src={bookdocLogo} className="menu-logo" alt="bookdoc-logo" />
          </NavLink>
        </div>

        <div id="desktop-sidebar-links" className="pt-6 bg-grey">
          <div className="link-containers">
            <SidebarLinks />
          </div>

          <div className="contact-us-info p-3 border border-top w-100" style={{ fontSize: '0.8em' }}>
            <b className="d-flex align-items-center mb-2" style={{ fontSize: '1.1em' }}><span className="me-1">&copy;</span>BookDoc {moment().year()}</b>
            <p className="text-muted">Contact 1300-88-2362<br/>Email: care@bookdoc.com</p>
          </div>
        </div>
      </div>
    );
  }
}