import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'caring_stores/';

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + 'login', { email, password })
      .then(response => {
        if (response.data.auth_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('caringstorename');
  }

  getCurrentUser() {
    const user  = JSON.parse(localStorage.getItem('user'));
    return user;
  }
}

export default new AuthService();