import { Component } from 'react';
import moment from 'moment';

export class Footer extends Component {
  render() {
    return (
        <div className="footer end-campaign">
            <div className="left">© {moment().year()} BookDoc. All Rights Reserved</div>
            <div className="right">
                <div><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/download-bookdoc-app/`}>Download BookDoc App</a></div>
                <div><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/faqs`}>FAQs</a></div>
                <div><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact-us`}>Contact Us</a></div>
                <div><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/privacy-policy/`}>Privacy Policy</a></div>
                <div><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/terms-of-use/`}>Terms of Use</a></div>     
            </div>
        </div>
    );
  }
}