import React, { Component }     from "react";
import { RecentRegistrations }  from 'components/admin/Admin/RecentRegistrations';
import AuthService              from 'services/auth.service';
import AdminsService            from 'services/admins.service';
import moment                   from 'moment';
import Spinner                  from 'react-bootstrap/Spinner';
import PreviousBtn              from 'assets/images/Previous.svg';
import NextBtn                  from 'assets/images/Next.svg';

let currentUser = AuthService.getCurrentUser();

class Admin extends Component {
  constructor(props){
    super(props);
    this.state = {
      stores: [],
      registrations: [],
      search: '',
      search_text: '',
      total_registrations: 0,
      loading: false,
      start_date: '',
      end_date: '',
      page: 1,
      total_pages: 1
    }
  }

  reloadList() {
    const params = {
      start_date: this.state.start_date,
      end_date:   this.state.end_date,
      page:       this.state.page
    }

    AdminsService.dashboard(params).then(
      (data) => {
        this.setState({
          stores: data.stores,
          registrations: data.registrations,
          total_registrations: data.total,
          page: data.metadata.current_page,
          total_pages: data.metadata.total_pages
        });
      },
      (error) => {
        console.log(error.response);

        if (error.response.status === 401) {
          AuthService.logout();
          window.location.replace('/admin/login');
        }
      }
    );
  }

  downloadCsv() {
    if (this.state.loading) { return }
    this.setState({ loading: true });

    const params = {
      start_date: this.state.start_date,
      end_date:   this.state.end_date
    }

    AdminsService.downloadCsv(params).then(
      (data) => {
        this.setState({ loading: false });
        const url       = window.URL.createObjectURL(data);
        const link      = document.createElement('a');
        link.href       = url;
        const filename  = `${ moment(new Date()).format('YYYY-MM-DD') } - ${ currentUser?.store_name } - Attendees Export.csv`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
      (error) => {
        console.log(error.response);

        if (error.response.status === 401) {
          AuthService.logout();
          window.location.replace('/eb/login');
        }
      }
    );
  }

  componentDidMount() {
    this.reloadList();
  }

  handlePage(previous) {
    let pageNumber    = this.state.page;
    const totalPages  = this.state.total_pages;

    if (previous) {
      pageNumber = pageNumber === 1 ? 1 : pageNumber - 1;
    } else {
      pageNumber = pageNumber === totalPages ? totalPages : pageNumber + 1;
    }

    this.setState({ page: pageNumber }, this.reloadList);
  }

  handleSearch() {
    if (this.state.start_date && this.state.end_date) {
      if(this.state.start_date > this.state.end_date){
        alert('Invalid date range');
      }else{
        this.setState({ page: 1 }, this.reloadList);
      }
    } else {
      alert('Must have both start date and end date');
    }
  }

  render() {
    const ranges  = [];
    const gapSize = 1000;
    const maxSize = Math.floor(this.state.total_registrations / gapSize) + 1;

    for(let i = 0; i < maxSize; i++) {
      ranges.push(i);
    }

    const selectOptions = ranges.map((value) => {
      const minRange  = (value * gapSize) + 1;
      let maxRange    = (value + 1) * gapSize;
      maxRange        = this.state.total_registrations < maxRange ? this.state.total_registrations : maxRange;

      return <option key={ value } value={ value }>
        { minRange } - { maxRange }
      </option>;
    });

    return (
      <div className="admin-page">
        <h4 style={{ fontWeight: 'bold' }}>Pharmacy Portal</h4>
        <p>Welcome Back, Admin.</p>

        <div className="d-flex align-items-center justify-content-between">

        <input type="date" placeholder="" name="start_date"
            value={ this.state.start_date }
            onChange={(e) => this.setState({ start_date: e.target.value }) }
            className="form-control"
            style={{ width: '20em' }}
          />

          <span className="mx-3">to</span>

          <input type="date" placeholder="" name="end_date"
            value={ this.state.end_date }
            onChange={(e) => this.setState({ end_date: e.target.value }) }
            className="form-control"
            style={{ width: '20em' }}
          />

          <button
            className="btn btn-primary ms-3 px-4"
            style={{ border: '1px solid #233577', background: '#233577', fontWeight: '800' }}
            onClick={() => this.handleSearch() }
          >
            Filter
          </button>

          {!this.state.loading && 
            <button
            className="btn btn-primary ms-3 px-4"
            style={{ border: '1px solid #233577', background: '#233577', fontWeight: '800' }}
            onClick={() => this.downloadCsv() }
            >
              Download
            </button>
          }


          <div className="conditional-display" displayed={ this.state.loading.toString() }>
              <Spinner animation="border" className="spinner"/>
            </div>

          <div className="flex-grow-1"></div>

          <div className="d-flex align-items-center">
            <label className="ml-sm-4 my-2 my-sm-0 mb-0">
              <button onClick={() => this.handlePage(true) } style={{ border: "none", backgroundColor: "#e9ebff" }}>
                <img src={ PreviousBtn } alt="previous" className="page-prev-btn" />
              </button>

              <b className="font-size-6 px-2">{ this.state.page } of { this.state.total_pages }</b>

              <button onClick={() => this.handlePage(false) } style={{ border: "none", backgroundColor: "#e9ebff" }}>
                <img src={ NextBtn } alt="next" className="page-next-btn" />
              </button>
          </label>
          </div>
        </div>

        <h5 className="mt-4 mb-2">Completed Attendees</h5>

        <div style={{ fontSize: '0.8em' }}>
          { this.state.registrations.length > 0 ?
            <RecentRegistrations items={ this.state.registrations } handleReload={() => this.reloadList() } /> :
            <div className="pt-2">
              { this.state.search_text.length > 0 ?
                <p>NRIC '<i>{ this.state.search_text }</i> ' cannot be found</p> :
                <p>No attendees</p>
              }
            </div> }
        </div>
      </div>
    );
  }
}

export { Admin };