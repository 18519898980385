import AuthService    from 'services/auth.service';
import { NavLink }    from 'react-router-dom';

export const ProfileContent = (props) => {
  let currentUser         = AuthService.getCurrentUser();

  const logOut = () => {
    AuthService.logout();
    window.location.replace('/admin/login');
  };

  return(
    <div className="text-dark">
      <div className="ps-4 pe-4 me-4">
        <b className="user-profile-title">Outlet</b><br/>
        <p className="user-profile-name mb-0">{ currentUser.store_name }</p>
      </div>

      <div className="ps-4 pt-4" style={{ fontSize: '1.2em', fontWeight: '700' }}>
        <NavLink to="" className="fweight-400" onClick={ logOut }>
          Sign Out
        </NavLink>
      </div>
    </div>
  );
}