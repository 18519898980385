import authHeader       from 'services/auth-header';
import AuthService      from 'services/auth.service';
import { stringify }    from 'query-string';

export const apiService = {
    states,
    registration,
    stores,
    dateSlots,
    search_customer,
    update_info
};

function states(){
    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header},
    };

    return fetch(`${process.env.REACT_APP_API_URL}states/${process.env.REACT_APP_COUNTRY_ID}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error.message);
        });
}

function registration(data) {

    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: {...header},
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}nestle_registrations`, requestOptions)
        .then(data => {
            if(data.status === 422){
                return data.json();
            }else{
                return data;
            }
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error.message);
        });
}

function stores(){
    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header},
    };

    return fetch(`${process.env.REACT_APP_API_URL}nestle_registrations/list_of_states_and_pharmacies`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error.message);
        });
}

function dateSlots(id){
    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header},
    };

    return fetch(`${process.env.REACT_APP_API_URL}nestle_registrations/list_of_available_slots?caring_store_id=${ id }`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error.message);
        });
}

function search_customer(data){
    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}nestle_registrations/nop_admins/search_customer?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error);
            return error;
        });
}

function update_info(id, data){
    let header = {
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: {...header,...authHeader()},
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}nestle_registrations/nop_admins/update_info?id=${id}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ',
            error.message);
            return error;
        });
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            AuthService.logout();
            window.location.replace('/admin/login');
        }
        var error = (response && response.message) || response.statusText;
        if(response.status === 404){
            error = 'Not Found'
        }else if(response.status === 403){
            error = 'Forbidden'
        }else if(response.status === 423){
            error = 'Locked'
        }else if(error.length === 0){
            error = 'Internal Server Error'
        }
        return Promise.reject(error);
    }

    return response.json();
}