export const TopVolume = (props) => {
  const stores  = props.volume_by_store;
  const keys    = stores ? Object.keys(stores) : [];

  const outlets = keys.map((key, index) => {
    return(
      <tr key={ index }>
        <td className="pe-2">{ key }</td>
        <td className="text-end fw-bold">{ stores[key] }</td>
      </tr>
    )
  });

  return(
    <div className="top-volume shadow">
      <h4 className="fw-bold mb-4">Top Volume by Outlet</h4>

      <table className="top-volume-table w-100">
        <tbody>
          { outlets }
        </tbody>
      </table>
    </div>
  );
}