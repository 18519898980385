import { Component } from 'react';
import '../modules/global';
import Select from 'react-select';
import '../assets/styles/CaringStores.scss';

class CaringStores extends Component {
  constructor(props) {
    super(props);
    this.allStores = Object.values(global.constant.caring_stores).flat()
    this.state = {
        states: [{value: '',label: 'All States'}].concat(Object.entries(global.constant.caring_stores).map(([key,value]) => {return {value: key, label: key}})),
        stores: this.allStores
    };

    document.title = 'NOP 2024 - Caring Stores';
  }

  handleStateChange = (value) => {
    this.setState({
      stores: value.value === '' ? this.allStores : global.constant.caring_stores[value.value]
    })
  }

  render() {
    return (
      <div className="caring-stores mt-4 mb-4">
        <div className="text-center">
          <h1>NESTLÉ® OMEGA PLUS 30-Day Heart Health Journey</h1>
          <h2>List Of Participating Caring Stores</h2>
        </div>
        <Select options={this.state.states} onChange={this.handleStateChange} placeholder="States" className="state-select" defaultValue={{value: '',label: 'All States'}}/>

        <div className="stores-container">
          {
            this.state.stores.map((store, index) => (
              <div key={index} className="store-container">
                <div><b>{store[0]}</b></div>
                <div>{store[1]}</div>
                <div>{store[3]}</div>
                <div>{store[4]}</div>
                <hr/>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

class StayTune extends Component {
  render () {
    return (
      <div className="caring-stores">
        <div className="end-note">Stay Tuned For More Info</div>
    </div>
    );
  }
}

export default CaringStores;
