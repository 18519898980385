import IconTotalAttended    from 'assets/images/icons/total-attended-icon.svg';

export const MiniInformation = (props) => {
  return(
    <div id="mini-information-container">
      <div className="small-information per-row-3 d-flex p-3 shadow margin-right-1em">
        <img src={ IconTotalAttended } alt={ 'total-attended' } className="ms-2 me-3" />

        <p className="mb-0">
          Total Attended<br/>
          <b>{ props.total_attended }</b>
        </p>
      </div>
    </div>
  );
}