import React, { Component } from "react";
import { MiniInformation }  from 'components/admin/Stats/MiniInformation';
import { TopVolume }        from 'components/admin/Stats/TopVolume';
import AuthService          from 'services/auth.service';
import AdminsService        from 'services/admins.service';

class Statistics extends Component {
  constructor(props){
    super(props);
    this.state = {
      statistics: {},
      row_1_height: 1,
    }
  }

  reloadList() {
    AdminsService.statistics().then(
      (data) => {
        console.log(data);
        this.setState({
          statistics: data
        });
      },
      (error) => {
        console.log(error.response);

        if (error.response.status === 401) {
          AuthService.logout();
          window.location.replace('/admin/login');
        }
      }
    );
  };

  componentDidMount() {
    this.reloadList();
  }

  handleEnterKey(e) {
    if (e.key === 'Enter') {
      window.location.replace('/admin/search-customer');
    }
  }

  render() {
    return (
      <div className="admin-page">
        <h4 className="mb-4 fw-bold">Statistics</h4>

        <div id="statistic-content">
          <div id="left-part">
            <MiniInformation
              total_attended={ this.state.statistics.total_attended }
            />
          </div>

          <TopVolume volume_by_store={ this.state.statistics.volume_by_store }/>
        </div>
      </div>
    );
  }
}

export { Statistics };