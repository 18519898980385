import React, { Component } from "react";
import  { Navigate } from 'react-router-dom';

class ComponentError extends Component {
    render() {
        return <Navigate to='/' />
    };
}

export { ComponentError };
