import React              from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// import { Home }           from './Home';
import UserTemplate       from './UserTemplate';
import AdminTemplate      from './admin/AdminTemplate';
import { Admin }          from './admin/Admin';
import { SearchCustomer } from './admin/SearchCustomer';
import { Statistics }     from './admin/Statistics';
import Login              from './admin/Login';
import CaringStores       from './CaringStores';

import { ComponentError } from './ComponentError';

// Services
import AuthService        from 'services/auth.service';

const Main = () => {
  let currentUser     = AuthService.getCurrentUser();
  let params          = useLocation();

  const noLoginNeeded = [
    '/admin/login',
    '/'
  ]

  if (!currentUser && !noLoginNeeded.includes(params.pathname)) {
    window.location.replace('/admin/login');
  }

  return (
    <Routes>
      <Route path='/' element={<UserTemplate/>}>
        <Route exact path='/' element={<CaringStores/>} />
      </Route>

      <Route path='/admin/login' element={<UserTemplate/>}>
        <Route exact path='/admin/login' element={<Login/>} />
      </Route>

      <Route path='/admin/' element={<AdminTemplate/>}>
        <Route exact path='dashboard' element={<Admin/>} />
        <Route exact path='search-customer' element={<SearchCustomer/>} />
        <Route exact path='statistics' element={<Statistics/>} />
      </Route>

      <Route path="*" element={<ComponentError/>} />
    </Routes>
  );
}

export { Main };