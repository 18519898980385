import React, { Component } from 'react';
import { NavLink }          from 'react-router-dom';
import '../../../modules/global';

export class RecentRegistrations extends Component {
  render() {
    const registrations = this.props.items.map((hash, index) => {
      return (
        <tr key={ index } className="bg-white shadow border">
          <td className="p-3">{ hash.name }</td>
          <td className="p-3">{ hash.phone_number }</td>
          <td className="p-3">{ hash.visit_date }</td>
          <td className="p-3">{ hash.total_cholesterol.value }</td>
          <td className={`p-3 cholesterol_level ${hash.total_cholesterol.level}`}>{ hash.total_cholesterol.level.replace(/_/g, ' ') }</td>
          <td className="p-3">{ hash.caring_store_name }</td>

          <td className="p-3">
            <NavLink to={ `/admin/search-customer?nric=${ hash.nric }` }>
              View
            </NavLink>
          </td>
        </tr>
      )
    });

    return (
      <table id="dashboard-cards-table" className="w-100">
        <thead className="">
          <tr>
            {
              ['Name', 'Phone Number', 'Visit Date', 'Total Cholesterol (mmol/L)', 'Cholesterol Level', 'Caring Store', 'Actions'].map((name, index) => {
                return <th key={ 'table-header-' + name } className="px-3">{ name }</th>
              })
            }
          </tr>
        </thead>

        <tbody>
          { registrations }
        </tbody>
      </table>
    )
  }
}