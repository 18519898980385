import { Main } from './components';
import './App.css';

function App() {
  // console.log({ REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT });
  return (
    <div className="App">
        <Main />
    </div>
  );
}

export default App;
