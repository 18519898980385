import axios        from 'axios';
import authHeader   from 'services/auth-header';

class AdminsService  {
  dashboard(params) {
    return axios
      .get(process.env.REACT_APP_API_URL + 'nestle_registrations/nop_admins/dashboard', { headers: authHeader(), params: params })
      .then(response => {
        return response.data;
      }
    );
  }

  upcoming(params) {
    return axios
      .get(process.env.REACT_APP_API_URL + 'nestle_registrations/nop_admins/upcoming', { headers: authHeader(), params: params })
      .then(response => {
        return response.data;
      }
    );
  }

  statistics() {
    return axios
      .get(process.env.REACT_APP_API_URL + 'nestle_registrations/nop_admins/statistics', { headers: authHeader() })
      .then(response => {
        return response.data;
      }
    );
  }

  downloadCsv(params) {
    return axios
      .get(process.env.REACT_APP_API_URL + 'nestle_registrations/nop_admins/download_excel.csv?', { headers: authHeader(), params: params, responseType: 'blob' })
      .then(response => {
        return response.data;
      }
    );
  }
}

export default new AdminsService();