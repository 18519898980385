import React, { useState, useRef } from 'react';

import Form           from 'react-validation/build/form';
import CheckButton    from 'react-validation/build/button';
import AuthService    from 'services/auth.service';

const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger mt-2 ml-16 text-left" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const form      = useRef();
  const checkBtn  = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading]   = useState(false);
  const [message, setMessage]   = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        (data) => {
          window.location.replace('/admin/dashboard');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);

          if (resMessage === 'Request failed with status code 401') {
            setMessage('Incorrect email or password');
          } else {
            setMessage(resMessage);
          }
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div id="login-page" className="bg-white p-10 d-flex justify-content-center align-items-center">
      <div className="shadow" style={{ width: "24em", padding: "2em 3em" }}>
        <h2 className="text-primary text-center mb-4">Login</h2>

        <Form onSubmit={ handleLogin } ref={ form }>
          <div className="position-relative w-100 mb-4 font-size-7">
            <p className="text-lightgrey mb-2">Email</p>

            <input
              type="text"
              className="form-control rounded-pill px-4 w-100"
              name="email"
              value={ username }
              onChange={ onChangeUsername }
              validations={ [required] }
              placeholder=" "
            />

          </div>

          <div className="position-relative w-100 mt-4 mb-4 font-size-7">
            <p className="text-lightgrey mb-2">Password</p>

            <input
              type="password"
              className="form-control rounded-pill px-4 w-100"
              name="password"
              value={ password }
              onChange={ onChangePassword }
              validations={ [required] }
              placeholder=" "
            />
          </div>

          <div className="form-group">
            <button className="btn btn-danger text-white rounded-pill w-100 my-4 py-2" id="sign-in-btn" disabled={ loading || !username || !password }>
              { loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}

              <span>Sign In</span>
            </button>
          </div>

          { message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                { message }
              </div>
            </div>
          )}

          {/* Check button is hidden, but it is used for validation. */}
          <CheckButton style={{ display: "none" }} ref={ checkBtn } />
        </Form>
      </div>
    </div>
  )
}

export default Login;