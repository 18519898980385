import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';

import '../assets/styles/UserTemplate.scss';

function UserTemplate() {
  return (
    <div className="UserTemplate">
        <Header />
        <Outlet />
        <Footer />
    </div>
  );
}

export default UserTemplate;
