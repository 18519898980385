import { NavLink } from 'react-router-dom';
import AuthService from 'services/auth.service';

import NavIcon1 from 'assets/images/icons/nav-icon-1.svg';
import NavIcon2 from 'assets/images/icons/nav-icon-2.svg';
import NavIcon3 from 'assets/images/icons/nav-icon-3.svg';

let currentUser         = AuthService.getCurrentUser();

var parentNavbarList    = [
  { title: 'Completed',       image: NavIcon1, href: '/admin/dashboard' },
  { title: 'Search Customer', image: NavIcon2, href: '/admin/search-customer' },
  // { title: 'Upcoming',        image: NavIcon1, href: '/admin/upcoming' }
];

if (currentUser?.role === 'caring_store_hq_admin') {
  parentNavbarList.push({ title: 'Statistics', image: NavIcon3, href: '/admin/statistics' });
}

const toggleMobileDropdown = (props) => {
  props.triggerDropdown && props.triggerDropdown();
}

export const SidebarLinks = (props) => {
  const navbarElements = parentNavbarList.map((hash, index) => {
    return (
      <NavLink key={ index } to={ hash.href } className={({ isActive }) => "" + (isActive ? " is-active" : "")} onClick={() => toggleMobileDropdown(props) } >
        <nav key={ 'sidebar-link-' + hash.title } className="d-flex align-items-center ps-4 py-3">
          <img src={ hash.image } alt={ hash.title } className="ms-2 me-3" />
          { hash.title }
        </nav>
      </NavLink>
    );
  });

  return navbarElements;
}