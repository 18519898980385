import React, { Component } from 'react';
import { ProfileContent }   from './ProfileContent';
import CaringProfile        from 'assets/images/icons/caring-profile-logo.png';
import AuthService          from 'services/auth.service';

const ProfileBtn = (props) => {
  let currentUser         = AuthService.getCurrentUser();

  return(
    <div id="profile-button" className="d-flex align-items-center clickable">
      <img src={ CaringProfile } alt="caring-profile-logo" className="h-100 py-1" />

      <div className="ps-3 pe-4 me-4">
        <b className="user-profile-title">Outlet</b><br/>
        <p className="user-profile-name mb-0">{ currentUser.store_name }</p>
      </div>

      <div id="profile-popup" data-displayed={ props.displayDropdown }>
        <ProfileContent />
      </div>
    </div>
  );
}

export class DesktopNavbar extends Component {
  render() {
    return (
      <div id="main-banner" className="d-lg-flex d-none align-items-center justify-content-end py-3 pe-4">
        <ProfileBtn displayDropdown={ this.props.displayDropdown } />
      </div>
    )
  }
}