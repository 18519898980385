import React, { Component }                           from "react";

import { apiService } from 'services/api';
import { Form, Button } from "react-bootstrap";
import { Formik, ErrorMessage } from 'formik';
import './../../assets/styles/Customer.scss';
import '../../modules/global';
import AuthService from 'services/auth.service';

import * as yup from 'yup';
import moment from 'moment';

class SearchCustomer extends Component {
  constructor(props){
    super(props);

    this.params = new URLSearchParams(window.location.search);
    let currentUser = AuthService.getCurrentUser();
    this.is_admin = currentUser?.role === 'caring_store_admin';

    this.base_registry = {
        id: '',
        name: '',
        email: '',
        phone_number: '',
        gender: '',
        dob: '',
        visit_date: ''
    }

    this.base_update = {
      total_cholesterol: "",
      caring_store_id: this.is_admin ? currentUser.caring_stores[0].id : ''
    }

    this.state = {
      stores: [],
      states: [],
      search: {
        nric: this.params.get('nric') || ''
      },
      registry: this.base_registry,
      update: this.base_update,
      formErrors: [],
      info: {},
      maxDate: moment().format("YYYY-MM-DD"),
      reading_range: [],
      reading: ''
    }

    this.searchCustomer = this.searchCustomer.bind(this);
  }

  updateSchema = yup.object().shape({
    total_cholesterol: yup.number().required().label('Total Cholesterol').positive()
  });

  componentDidMount() {
    if(this.params.get('nric')){
      this.searchCustomer();
    }
  }

  handleUpdate(formData){
    this.setState(prevState => ({
      info: {
          ...prevState.info,
          total_cholesterol: formData.total_cholesterol,
          caring_store_id: this.base_update.caring_store_id
      }
    }), () => {
      this.update();
    });
  }

  update(){
    if(this.state.registry.id){
      apiService.update_info(this.state.registry.id, this.state.info)
      .then(
          response => {
            if(response && response.hasOwnProperty('errors')){
              this.setState({
                  formErrors: response.errors
              });
            }else if(response === 'Locked'){
              alert("Unable to proceed due to hitting redemption limit.");
            }else{
              if(this.params.get('phone_number')){
                window.location.replace('/admin/dashboard');
              }else{
                alert("Updated successfully");
              }
            }
          },
          error => {
              this.setState({ error, loading: false })
          }
      );
    }
  }

  searchCustomer(){
    apiService.search_customer(this.state.search)
    .then(
        response => {
          var errMessage = '';
          if([undefined, 'Not Found', 'Forbidden', 'Locked'].includes(response)){
            if(response === 'Forbidden'){
              errMessage = "Customer has registered in another Caring Store."
            }else if(response === 'Locked'){
              errMessage = "Unable to proceed due to hitting redemption limit."
            }else{
              errMessage = 'Not Found'
            }

            this.setState({
              searchError: errMessage,
              registry: this.base_registry,
              update: this.base_update,
              reading: ''
            })
          }else{
            this.setState({
              registry: response.registration,
              reading_range: response.reading_range,
              searchError: null,
              reading: response.registration.total_cholesterol ? response.registration.total_cholesterol.level : '',
              update: {
                total_cholesterol: response.registration.total_cholesterol ? response.registration.total_cholesterol.value : ''
              }
            })
          }
        },
        error => {
            this.setState({ error, loading: false })
        }
    );
  }

  reading(value){
    let level = null;
    if(value){
      this.state.reading_range.forEach((range) => {
        if(value >= range.min && (range.max === null || value < range.max)){
          level = range.level
        }
      })
    }
    this.setState({reading: level})
  }
  
  render() {
    return (
      <div className="customer-page">
        <h4 style={{ fontWeight: 'bold' }}>Search Customer</h4>

        <div className="content-container">
          <div className="search-row">
              <Form.Group className="mb-3 d-flex">
                  <Form.Control className="search-field" placeholder="Search by NRIC. (e.g. 901010101234)" name="nric" value={this.state.search.nric} onChange={(e) => this.setState({ search: { nric: e.target.value} }) }/>
                  <Button className="search-btn" type="button" onClick={this.searchCustomer} >Search</Button>
              </Form.Group>
              {this.state.searchError &&
                <div className="search-error">{this.state.searchError}</div>
              }
          </div>

          <Formik enableReinitialize validationSchema={this.updateSchema} onSubmit={(form, actions) => this.handleUpdate(form, actions)} initialValues={this.state.update}>
            {({
                handleSubmit,
                handleChange,
                values,
            }) => (
              <React.Fragment>
                <div className="info-container">
                  <h4 style={{ fontWeight: 'bold' }}>Customer Information</h4>

                  <div className="d-flex justify-content-between field-row">
                      <Form.Group className="mb-3 field-col">
                          <Form.Label>Name</Form.Label>
                          <Form.Control name='name' type="text" value={this.state.registry.name || ''} readOnly disabled/>
                          <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </Form.Group>

                      <Form.Group className="mb-3 field-col">
                          <Form.Label>Date Of Birth</Form.Label>
                          <Form.Control name="dob" type="date" value={this.state.registry.dob || ''} readOnly disabled />
                          <ErrorMessage component="div" name="dob" className="invalid-feedback" />
                      </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between field-row">
                      <Form.Group className="mb-3 field-col">
                          <Form.Label>Email</Form.Label>
                          <Form.Control name="email" type="email" value={this.state.registry.email || ''} readOnly disabled/>
                          <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </Form.Group>

                      <Form.Group className="mb-3 field-col">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control name="phone_number" type="text" value={this.state.registry.phone_number || ''} readOnly disabled/>
                          <ErrorMessage component="div" name="phone_number" className="invalid-feedback" />
                      </Form.Group>
                  </div>

                  <div className="d-flex justify-content-between field-row select-field">
                      <Form.Group className="mb-3 field-col field-row select-field">
                          <Form.Label>Gender</Form.Label>
                          <Form.Control name='gender' type="text" value={this.state.registry.gender || ''} readOnly disabled/>
                          <ErrorMessage component="div" name="gender" className="invalid-feedback" />
                      </Form.Group>

                      {!this.is_admin &&
                        <Form.Group className="mb-3 field-col field-row select-field">
                            <Form.Label>Visit Date</Form.Label>
                            <Form.Control name='visit_date' type="text" value={this.state.registry.visit_date} readOnly disabled/>
                            <ErrorMessage component="div" name="visit_date" className="invalid-feedback" />
                        </Form.Group>
                      }
                  </div>
                </div>

                <div className="form-container">
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="d-flex w-100 justify-content-between field-row">
                    <Form.Group className="mb-3 field-col">
                      <Form.Label>Total Cholesterol (mmol/L) </Form.Label> 
                      {this.state.reading && <span className={`cholesterol_level ms-2 ${this.state.reading}`}><small><i>{this.state.reading.replace(/_/g, ' ')}</i></small></span> }
                      <Form.Control name="total_cholesterol" 
                        type="number" 
                        value={values.total_cholesterol} 
                        min={0.00} 
                        precision={2} 
                        onChange={(e) => {
                          this.reading(e.target.value);
                          handleChange(e);
                        }} 
                        disabled={!this.is_admin ? 'disabled' : ''}/>
                      <ErrorMessage component="div" name="total_cholesterol" className="invalid-feedback" />
                    </Form.Group>
                  </div>
                  <ul className="form-errors">
                      {Object.entries(this.state.formErrors).map((key,i) =>
                          <li key={i}>{key[0]}: {key[1]}</li>
                      )}
                  </ul>
                  {this.is_admin &&
                    <div className="d-flex justify-content-end">
                      <Button variant="primary" type="submit" className="submit-btn">
                          Save
                      </Button>
                    </div>
                  }
                </Form>
                </div>
              </React.Fragment>
            )}
          </Formik>

        </div>
      </div>
    );
  }
}

export { SearchCustomer };