import React, { Component } from 'react';
import CaringProfile        from 'assets/images/icons/caring-profile-logo.png';
import MobileMenu           from 'assets/images/icons/Mobile Menu.svg';
import { NavLink }          from 'react-router-dom';
import { ProfileContent }   from 'components/admin/DesktopNavbar/ProfileContent';
import { SidebarLinks }     from 'components/admin/DesktopSidebar/SidebarLinks';

export class MobileNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileDropdown: false
    };
  }

  toggleDropdown() {
    var body = document.querySelector('body');

    if (this.state.profileDropdown) {
      body.classList.remove('modal-open');
      body.style.removeProperty('overflow');
    } else {
      body.classList.add('modal-open');
      body.setAttribute('style', 'overflow: hidden;');
    }

    this.setState({ profileDropdown: !this.state.profileDropdown });
  }

  render() {
    return (
      <div id="mobile-navbar" className="d-block d-lg-none">
        <div id="mobile-navbar-bg" className="d-flex justify-content-between align-items-center bg-white">
          <NavLink to="/eb" className="h-100" >
            <img src={ CaringProfile } alt="caring-logo" className="p-3 h-100"/>
          </NavLink>

          <img src={ MobileMenu } alt="mobile-menu" className="p-3 h-100" onClick={() => this.toggleDropdown() } />
        </div>

        <div id="mobile-popup" data-displayed={ this.state.profileDropdown }>
          <div id="mobile-popup-links">
            <SidebarLinks triggerDropdown={() => this.toggleDropdown() } />
          </div>

          <div id="mobile-popup-profile" style={{ fontSize: '0.8em' }}>
            <ProfileContent triggerDropdown={() => this.toggleDropdown() } />
          </div>
        </div>
      </div>
    )
  }
}