module.exports = global.constant = {
    minDate: "1900-01-01",
    genders: [
      { value: 'M', label: 'Male' },
      { value: 'F', label: 'Female' },
      { value: 'PNTS', label: 'Prefer not to say'}
    ],
    races: [
      { value: 'Malay', label: 'Malay' },
      { value: 'Chinese', label: 'Chinese' },
      { value: 'Indian', label: 'Indian' },
      { value: 'Others', label: 'Others' }
    ],
    afs: [
      { value: true, label: 'Yes'},
      { value: false, label: 'No'}
    ],
    blood_pressures: [
      { value: 'normal', label: 'Normal'},
      { value: 'prehypertension', label: 'Prehypertension'},
      { value: 'hypertension', label: 'Hypertension'}
    ],
    cholesterols: [
      { value: 'desirable', label: 'Desirable'},
      { value: 'borderline_high', label: 'Borderline High'},
      { value: 'high', label: 'High'}
    ],
    countries: [
      {
        "name":"Afghanistan",
        "code":"AF",
        "dial_code":"+93"
      },
      {
        "name":"Albania",
        "code":"AL",
        "dial_code":"+355"
      },
      {
        "name":"Algeria",
        "code":"DZ",
        "dial_code":"+213"
      },
      {
        "name":"American Samoa",
        "code":"AS",
        "dial_code":"+1-684"
      },
      {
        "name":"Andorra",
        "code":"AD",
        "dial_code":"+376"
      },
      {
        "name":"Angola",
        "code":"AO",
        "dial_code":"+244"
      },
      {
        "name":"Anguilla",
        "code":"AI",
        "dial_code":"+1-264"
      },
      {
        "name":"Antarctica",
        "code":"AQ",
        "dial_code":"+672"
      },
      {
        "name":"Antigua and Barbuda",
        "code":"AG",
        "dial_code":"+1-268"
      },
      {
        "name":"Argentina",
        "code":"AR",
        "dial_code":"+54"
      },
      {
        "name":"Armenia",
        "code":"AM",
        "dial_code":"+374"
      },
      {
        "name":"Aruba",
        "code":"AW",
        "dial_code":"+297"
      },
      {
        "name":"Australia",
        "code":"AU",
        "dial_code":"+61"
      },
      {
        "name":"Austria",
        "code":"AT",
        "dial_code":"+43"
      },
      {
        "name":"Azerbaijan",
        "code":"AZ",
        "dial_code":"+994"
      },
      {
        "name":"Bahamas",
        "code":"BS",
        "dial_code":"+1-242"
      },
      {
        "name":"Bahrain",
        "code":"HB",
        "dial_code":"+973"
      },
      {
        "name":"Bangladesh",
        "code":"BD",
        "dial_code":"+880"
      },
      {
        "name":"Barbados",
        "code":"BB",
        "dial_code":"+1-264"
      },
      {
        "name":"Belarus",
        "code":"BY",
        "dial_code":"+375"
      },
      {
        "name":"Belgium",
        "code":"BE",
        "dial_code":"+32"
      },
      {
        "name":"Belize",
        "code":"BZ",
        "dial_code":"+501"
      },
      {
        "name":"Benin",
        "code":"BJ",
        "dial_code":"+229"
      },
      {
        "name":"Bermuda",
        "code":"BM",
        "dial_code":"+1-441"
      },
      {
        "name":"Bhutan",
        "code":"BT",
        "dial_code":"+975"
      },
      {
        "name":"Bolivia",
        "code":"BO",
        "dial_code":"+591"
      },
      {
        "name":"Bosnia and Herzegovina",
        "code":"BA",
        "dial_code":"+387"
      },
      {
        "name":"Botswana",
        "code":"BW",
        "dial_code":"+267"
      },
      {
        "name":"Bouvet Island",
        "code":"BV",
        "dial_code":"+47"
      },
      {
        "name":"Brazil",
        "code":"BR",
        "dial_code":"+55"
      },
      {
        "name":"British Indian Ocean Territory",
        "code":"IO",
        "dial_code":"+246"
      },
      {
        "name":"British Virgin Islands",
        "code":"VG",
        "dial_code":"+1-284"
      },
      {
        "name":"Brunei",
        "code":"BN",
        "dial_code":"+673"
      },
      {
        "name":"Bulgaria",
        "code":"BG",
        "dial_code":"+359"
      },
      {
        "name":"Burkina Faso",
        "code":"BF",
        "dial_code":"+226"
      },
      {
        "name":"Burundi",
        "code":"BI",
        "dial_code":"+257"
      },
      {
        "name":"Cabo Verde",
        "code":"CV",
        "dial_code":"+238"
      },
      {
        "name":"Cambodia",
        "code":"KH",
        "dial_code":"+855"
      },
      {
        "name":"Cameroon",
        "code":"CM",
        "dial_code":"+237"
      },
      {
        "name":"Canada",
        "code":"CA",
        "dial_code":"+1"
      },
      {
        "name":"Cayman Islands",
        "code":"KY",
        "dial_code":"+1-345"
      },
      {
        "name":"Central African Republic",
        "code":"CF",
        "dial_code":"+236"
      },
      {
        "name":"Chad",
        "code":"TD",
        "dial_code":"+235"
      },
      {
        "name":"Chile",
        "code":"CL",
        "dial_code":"+56"
      },
      {
        "name":"China",
        "code":"CN",
        "dial_code":"+86"
      },
      {
        "name":"Christmas Island",
        "code":"CX",
        "dial_code":"+61"
      },
      {
        "name":"Cocos (Keeling) Islands",
        "code":"CC",
        "dial_code":"+61"
      },
      {
        "name":"Colombia",
        "code":"CO",
        "dial_code":"+57"
      },
      {
        "name":"Comoros",
        "code":"KM",
        "dial_code":"+269"
      },
      {
        "name":"Congo",
        "code":"CG",
        "dial_code":"+242"
      },
      {
        "name":"Cook Islands",
        "code":"CK",
        "dial_code":"+682"
      },
      {
        "name":"Costa Rica",
        "code":"CR",
        "dial_code":"+506"
      },
      {
        "name":"Côte d’Ivoire",
        "code":"CI",
        "dial_code":"+225"
      },
      {
        "name":"Croatia",
        "code":"HR",
        "dial_code":"+385"
      },
      {
        "name":"Cuba",
        "code":"CU",
        "dial_code":"+53"
      },
      {
        "name":"Cyprus",
        "code":"CY",
        "dial_code":"+357"
      },
      {
        "name":"Czech Republic",
        "code":"CZ",
        "dial_code":"+420"
      },
      {
        "name":"Denmark",
        "code":"DK",
        "dial_code":"+45"
      },
      {
        "name":"Djibouti",
        "code":"DJ",
        "dial_code":"+253"
      },
      {
        "name":"Dominica",
        "code":"DM",
        "dial_code":"+1-767"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-809"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-829"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-849"
      },
      {
        "name":"Ecuador",
        "code":"EC",
        "dial_code":"+593"
      },
      {
        "name":"Egypt",
        "code":"EG",
        "dial_code":"+20"
      },
      {
        "name":"El Salvador",
        "code":"SV",
        "dial_code":"+503"
      },
      {
        "name":"Equatorial Guinea",
        "code":"GQ",
        "dial_code":"+240"
      },
      {
        "name":"Eritrea",
        "code":"ER",
        "dial_code":"+291"
      },
      {
        "name":"Estonia",
        "code":"EE",
        "dial_code":"+372"
      },
      {
        "name":"Ethiopia",
        "code":"ET",
        "dial_code":"+251"
      },
      {
        "name":"Falkland Islands",
        "code":"FK",
        "dial_code":"+500"
      },
      {
        "name":"Fiji",
        "code":"FJ",
        "dial_code":"+679"
      },
      {
        "name":"Finland",
        "code":"FI",
        "dial_code":"+358"
      },
      {
        "name":"France",
        "code":"FR",
        "dial_code":"+33"
      },
      {
        "name":"French Guiana",
        "code":"GF",
        "dial_code":"+594"
      },
      {
        "name":"French Polynesia",
        "code":"PF",
        "dial_code":"+689"
      },
      {
        "name":"Gabon",
        "code":"GA",
        "dial_code":"+241"
      },
      {
        "name":"Gambia",
        "code":"GM",
        "dial_code":"+220"
      },
      {
        "name":"Georgia",
        "code":"GE",
        "dial_code":"+995"
      },
      {
        "name":"Germany",
        "code":"DE",
        "dial_code":"+49"
      },
      {
        "name":"Ghana",
        "code":"GH",
        "dial_code":"+233"
      },
      {
        "name":"Gibraltar",
        "code":"GI",
        "dial_code":"+350"
      },
      {
        "name":"Greece",
        "code":"GR",
        "dial_code":"+30"
      },
      {
        "name":"Greenland",
        "code":"GL",
        "dial_code":"+299"
      },
      {
        "name":"Grenada",
        "code":"GD",
        "dial_code":"+1-473"
      },
      {
        "name":"Guadeloupe",
        "code":"GU",
        "dial_code":"+1-671"
      },
      {
        "name":"Guatemala",
        "code":"GT",
        "dial_code":"+502"
      },
      {
        "name":"Guinea",
        "code":"GN",
        "dial_code":"+224"
      },
      {
        "name":"Guinea-Bissau",
        "code":"GW",
        "dial_code":"+245"
      },
      {
        "name":"Guyana",
        "code":"GY",
        "dial_code":"+592"
      },
      {
        "name":"Haiti",
        "code":"HT",
        "dial_code":"+509"
      },
      {
        "name":"Heard Island and McDonald Islands",
        "code":"HM",
        "dial_code":"+334"
      },
      {
        "name":"Holy See (Vatican City)</ +504",
        "code":"VA",
        "dial_code":"+379"
      },
      {
        "name":"Hong Kong",
        "code":"HK",
        "dial_code":"+854"
      },
      {
        "name":"Hungary",
        "code":"HU",
        "dial_code":"+36"
      },
      {
        "name":"Iceland",
        "code":"IS",
        "dial_code":"+354"
      },
      {
        "name":"India",
        "code":"IN",
        "dial_code":"+91"
      },
      {
        "name":"Indonesia",
        "code":"ID",
        "dial_code":"+62"
      },
      {
        "name":"Iran",
        "code":"IR",
        "dial_code":"+98"
      },
      {
        "name":"Iraq",
        "code":"IQ",
        "dial_code":"+964"
      },
      {
        "name":"Ireland",
        "code":"IE",
        "dial_code":"+353"
      },
      {
        "name":"Israel",
        "code":"IL",
        "dial_code":"+972"
      },
      {
        "name":"Italy",
        "code":"IT",
        "dial_code":"+39"
      },
      {
        "name":"Jamaica",
        "code":"JM",
        "dial_code":"+1-876"
      },
      {
        "name":"Japan",
        "code":"JP",
        "dial_code":"+81"
      },
      {
        "name":"Jordan",
        "code":"JO",
        "dial_code":"+962"
      },
      {
        "name":"Kazakhstan",
        "code":"KZ",
        "dial_code":"+7"
      },
      {
        "name":"Kenya",
        "code":"KE",
        "dial_code":"+354"
      },
      {
        "name":"Kiribati",
        "code":"KI",
        "dial_code":"+686"
      },
      {
        "name":"Korea",
        "code":"KR",
        "dial_code":"+82"
      },
      {
        "name":"Kuwait",
        "code":"KW",
        "dial_code":"+965"
      },
      {
        "name":"Kyrgyzstan",
        "code":"KG",
        "dial_code":"+996"
      },
      {
        "name":"Laos",
        "code":"LA",
        "dial_code":"+856"
      },
      {
        "name":"Latvia",
        "code":"LV",
        "dial_code":"+371"
      },
      {
        "name":"Lebanon",
        "code":"LB",
        "dial_code":"+961"
      },
      {
        "name":"Lesotho",
        "code":"LS",
        "dial_code":"+266"
      },
      {
        "name":"Liberia",
        "code":"LR",
        "dial_code":"+231"
      },
      {
        "name":"Libya",
        "code":"LY",
        "dial_code":"+218"
      },
      {
        "name":"Liechtenstein",
        "code":"LI",
        "dial_code":"+423"
      },
      {
        "name":"Luxembourg",
        "code":"LU",
        "dial_code":"+352"
      },
      {
        "name":"Macao SAR",
        "code":"MO",
        "dial_code":"+853"
      },
      {
        "name":"Macedonia",
        "code":"MK",
        "dial_code":"+807"
      },
      {
        "name":"Madagascar",
        "code":"MG",
        "dial_code":"+450"
      },
      {
        "name":"Malawi",
        "code":"MW",
        "dial_code":"+454"
      },
      {
        "name":"Malaysia",
        "dial_code":"+60",
        "code":"MY"
      },
      {
        "name":"Maldives",
        "code":"VM",
        "dial_code":"+960"
      },
      {
        "name":"Mali",
        "code":"ML",
        "dial_code":"+223"
      },
      {
        "name":"Malta",
        "code":"MT",
        "dial_code":"+356"
      },
      {
        "name":"Marshall Islands",
        "code":"MH",
        "dial_code":"+692"
      },
      {
        "name":"Martinique",
        "code":"MQ",
        "dial_code":"+596"
      },
      {
        "name":"Mauritania",
        "code":"MR",
        "dial_code":"+222"
      },
      {
        "name":"Mauritius",
        "code":"MU",
        "dial_code":"+230"
      },
      {
        "name":"Mayotte",
        "code":"YT",
        "dial_code":"+262"
      },
      {
        "name":"Mexico",
        "code":"MX",
        "dial_code":"+52"
      },
      {
        "name":"Micronesia",
        "code":"FM",
        "dial_code":"+691"
      },
      {
        "name":"Moldova",
        "code":"MD",
        "dial_code":"+373"
      },
      {
        "name":"Monaco",
        "code":"MC",
        "dial_code":"+377"
      },
      {
        "name":"Mongolia",
        "code":"MG",
        "dial_code":"+976"
      },
      {
        "name":"Montenegro",
        "code":"ME",
        "dial_code":"+382"
      },
      {
        "name":"Montserrat",
        "code":"MS",
        "dial_code":"+1-664"
      },
      {
        "name":"Morocco",
        "code":"MA",
        "dial_code":"+212"
      },
      {
        "name":"Mozambique",
        "code":"MZ",
        "dial_code":"+258"
      },
      {
        "name":"Myanmar",
        "code":"MM",
        "dial_code":"+95"
      },
      {
        "name":"Namibia",
        "code":"NA",
        "dial_code":"+264"
      },
      {
        "name":"Nauru",
        "code":"NR",
        "dial_code":"+674"
      },
      {
        "name":"Nepal",
        "code":"NP",
        "dial_code":"+977"
      },
      {
        "name":"Netherlands",
        "code":"NL",
        "dial_code":"+31"
      },
      {
        "name":"New Caledonia",
        "code":"NC",
        "dial_code":"+687"
      },
      {
        "name":"New Zealand",
        "code":"NZ",
        "dial_code":"+64"
      },
      {
        "name":"Nicaragua",
        "code":"NI",
        "dial_code":"+505"
      },
      {
        "name":"Niger",
        "code":"NE",
        "dial_code":"+227"
      },
      {
        "name":"Nigeria",
        "code":"NG",
        "dial_code":"+234"
      },
      {
        "name":"Niue",
        "code":"NU",
        "dial_code":"+683"
      },
      {
        "name":"Norfolk Island",
        "code":"NF",
        "dial_code":"+672"
      },
      {
        "name":"Northern Mariana Islands",
        "code":"MP",
        "dial_code":"+1-670"
      },
      {
        "name":"North Korea",
        "code":"KP",
        "dial_code":"+850"
      },
      {
        "name":"Norway",
        "code":"NO",
        "dial_code":"+47"
      },
      {
        "name":"Oman",
        "code":"OM",
        "dial_code":"+968"
      },
      {
        "name":"Pakistan",
        "code":"PK",
        "dial_code":"+92"
      },
      {
        "name":"Palau",
        "code":"PW",
        "dial_code":"+680"
      },
      {
        "name":"Palestinian Authority",
        "code":"PS",
        "dial_code":"+970"
      },
      {
        "name":"Panama",
        "code":"PA",
        "dial_code":"+507"
      },
      {
        "name":"Papua New Guinea",
        "code":"PG",
        "dial_code":"+675"
      },
      {
        "name":"Paraguay",
        "code":"PY",
        "dial_code":"+595"
      },
      {
        "name":"Peru",
        "code":"PE",
        "dial_code":"+51"
      },
      {
        "name":"Philippines",
        "code":"PH",
        "dial_code":"+63"
      },
      {
        "name":"Pitcairn Islands",
        "code":"PN",
        "dial_code":"+64"
      },
      {
        "name":"Poland",
        "code":"PL",
        "dial_code":"+48"
      },
      {
        "name":"Portugal",
        "code":"PT",
        "dial_code":"+351"
      },
      {
        "name":"Puerto Rico",
        "code":"PR",
        "dial_code":"+1-787"
      },
      {
        "name":"Puerto Rico",
        "code":"PR",
        "dial_code":"+1-939"
      },
      {
        "name":"Qatar",
        "code":"QA",
        "dial_code":"+974"
      },
      {
        "name":"Reunion",
        "code":"RE",
        "dial_code":"+262"
      },
      {
        "name":"Romania",
        "code":"RO",
        "dial_code":"+40"
      },
      {
        "name":"Russia",
        "code":"RU",
        "dial_code":"+7"
      },
      {
        "name":"Rwanda",
        "code":"RW",
        "dial_code":"+250"
      },
      {
        "name":"Saint Kitts and Nevis",
        "code":"KN",
        "dial_code":"+1-689"
      },
      {
        "name":"Saint Lucia",
        "code":"LC",
        "dial_code":"+1-758"
      },
      {
        "name":"Saint Pierre and Miquelon",
        "code":"PM",
        "dial_code":"+508"
      },
      {
        "name":"Saint Vincent and the Grenadines",
        "code":"VC",
        "dial_code":"+1-784"
      },
      {
        "name":"Samoa",
        "code":"WS",
        "dial_code":"+658"
      },
      {
        "name":"San Marino",
        "code":"SM",
        "dial_code":"+378"
      },
      {
        "name":"São Tomé and Príncipe",
        "code":"ST",
        "dial_code":"+239"
      },
      {
        "name":"Saudi Arabia",
        "code":"SA",
        "dial_code":"+966"
      },
      {
        "name":"Senegal",
        "code":"SN",
        "dial_code":"+221"
      },
      {
        "name":"Serbia",
        "code":"SR",
        "dial_code":"+381"
      },
      {
        "name":"Seychelles",
        "code":"SC",
        "dial_code":"+248"
      },
      {
        "name":"Sierra Leone",
        "code":"SL",
        "dial_code":"+232"
      },
      {
        "name":"Singapore",
        "code":"SG",
        "dial_code":"+65"
      },
      {
        "name":"Slovakia",
        "code":"SK",
        "dial_code":"+421"
      },
      {
        "name":"Slovenia",
        "code":"SI",
        "dial_code":"+386"
      },
      {
        "name":"Solomon Islands",
        "code":"SB",
        "dial_code":"+677"
      },
      {
        "name":"Somalia",
        "code":"SO",
        "dial_code":"+252"
      },
      {
        "name":"South Africa",
        "code":"ZA",
        "dial_code":"+27"
      },
      {
        "name":"South Georgia and the South Sandwich Islands",
        "code":"GS",
        "dial_code":"+500"
      },
      {
        "name":"Spain",
        "code":"ES",
        "dial_code":"+34"
      },
      {
        "name":"Sri Lanka",
        "code":"LK",
        "dial_code":"+94"
      },
      {
        "name":"St Helena",
        "code":"SH",
        "dial_code":"+290"
      },
      {
        "name":"Sudan",
        "code":"SD",
        "dial_code":"+249"
      },
      {
        "name":"Suriname",
        "code":"SR",
        "dial_code":"+597"
      },
      {
        "name":"Svalbard and Jan Mayen",
        "code":"SJ",
        "dial_code":"+47"
      },
      {
        "name":"Swaziland",
        "code":"SZ",
        "dial_code":"+268"
      },
      {
        "name":"Sweden",
        "code":"SE",
        "dial_code":"+46"
      },
      {
        "name":"Switzerland",
        "code":"CH",
        "dial_code":"+41"
      },
      {
        "name":"Syria",
        "code":"SY",
        "dial_code":"+963"
      },
      {
        "name":"Taiwan",
        "code":"TW",
        "dial_code":"+886"
      },
      {
        "name":"Tajikistan",
        "code":"TJ",
        "dial_code":"+992"
      },
      {
        "name":"Tanzania",
        "code":"TZ",
        "dial_code":"+255"
      },
      {
        "name":"Thailand",
        "code":"TH",
        "dial_code":"+66"
      },
      {
        "name":"Timor-Leste",
        "code":"TL",
        "dial_code":"+670"
      },
      {
        "name":"Togo",
        "code":"TG",
        "dial_code":"+228"
      },
      {
        "name":"Tokelau",
        "code":"TK",
        "dial_code":"+690"
      },
      {
        "name":"Tonga",
        "code":"TO",
        "dial_code":"+676"
      },
      {
        "name":"Trinidad and Tobago",
        "code":"TT",
        "dial_code":"+1-868"
      },
      {
        "name":"Tunisia",
        "code":"TN",
        "dial_code":"+261"
      },
      {
        "name":"Turkey",
        "code":"TR",
        "dial_code":"+90"
      },
      {
        "name":"Turkmenistan",
        "code":"TM",
        "dial_code":"+993"
      },
      {
        "name":"Turks and Caicos Islands",
        "code":"TC",
        "dial_code":"+1-649"
      },
      {
        "name":"Tuvalu",
        "code":"TV",
        "dial_code":"+688"
      },
      {
        "name":"Uganda",
        "code":"UG",
        "dial_code":"+256"
      },
      {
        "name":"Ukraine",
        "code":"UA",
        "dial_code":"+380"
      },
      {
        "name":"United Arab Emirates",
        "code":"AR",
        "dial_code":"+971"
      },
      {
        "name":"United Kingdom",
        "code":"GB",
        "dial_code":"+44"
      },
      {
        "name":"United States",
        "code":"US",
        "dial_code":"+1"
      },
      {
        "name":"Uruguay",
        "code":"UY",
        "dial_code":"+598"
      },
      {
        "name":"US Minor Outlying Islands",
        "code":"UM",
        "dial_code":"+1"
      },
      {
        "name":"US Virgin Islands",
        "code":"VI",
        "dial_code":"+1"
      },
      {
        "name":"Uzbekistan",
        "code":"UZ",
        "dial_code":"+998"
      },
      {
        "name":"Vanuatu",
        "code":"VU",
        "dial_code":"+678"
      },
      {
        "name":"Venezuela",
        "code":"VE",
        "dial_code":"+58"
      },
      {
        "name":"Vietnam",
        "code":"VN",
        "dial_code":"+84"
      },
      {
        "name":"Wallis and Futuna",
        "code":"WF",
        "dial_code":"+681"
      },
      {
        "name":"Yemen",
        "code":"YE",
        "dial_code":"+967"
      },
      {
        "name":"Zambia",
        "code":"ZM",
        "dial_code":"+260"
      },
      {
        "name":"Zimbabwe",
        "code":"ZW",
        "dial_code":"+263"
      }
    ],
    caring_stores: {
      'Johor': [
        ['CARiNG Pharmacy', 'Aeon Tebrau City, Johor Bahru', 'Johor', 'Lot F29B, 1st Floor, AEON Tebrau City Shopping Centre, Jalan Desa Tebrau, Taman Desa Tebrau, 81100 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'KSL City Mall, Johor Bahru', 'Johor', 'Lot 71 & 72, Level LG, KSL City Mall, No. 33, Jalan Seladang, Taman Abad, 80250 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Molek, Johor Bahru', 'Johor', '14, Jalan Molek 2/1, Taman Molek, 81100 Johor Bahru, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Larkin Sentral, Johor Bahru', 'Johor', 'Lot 5B-04A/1, G Floor, Kompleks Pengangkutan Awam Larkin, Jalan Geroda, 80350 Johor Bahru, Johor', 'Mon - Sun: 7:30am - 7:30pm'],
        ['CARiNG Pharmacy', 'Taman Perling, Johor Bahru', 'Johor', '274, Jalan Simbang, Taman Perling, 81200 Johor Bahru, Johor', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Taman Nusa Bestari, Iskandar Puteri', 'Johor', '32G, Jalan Bestari 2/2, Taman Nusa Bestari, 79150 Iskandar Puteri, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Sutera Mall, Skudai', 'Johor', 'Lot L1-086, 087, 088, Level 1, Sutera Mall, No. 1, Jalan Sutera Tanjung 8/4, Taman Sutera Utama, 81300 Skudai, Johor', 'Mon - Sun: 10:30am - 10:30pm'],
        ['CARiNG Pharmacy', 'Komtar JBCC, Johor Bahru', 'Johor', 'Lot 218, 2nd Floor, Komtar JBCC, Johor Bahru City Centre, 80000 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Mutiara Mas, Skudai', 'Johor', '9, Jalan Mutiara 1/2, Taman Mutiara Mas, 81300 Skudai, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Bandar Seri Alam, Masai', 'Johor', 'No. 8, Jalan Suria 2, Bandar Seri Alam, 81750 Masai, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Paradigm Mall, Johor Bahru', 'Johor', 'Lot UG-08, Paradigm Mall Johor Bahru, Jalan Skudai, 81200 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'JB City Square, Johor Bahru', 'Johor', 'Lot M2-18, 19, 20, 21, Level 2, Johor Bahru City Square, No. 106-108, Jalan Wong Ah Fook, 80000 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Mid Valley SouthKey, Johor Bahru', 'Johor', 'Lot F-022, The Mall Mid Valley SouthKey, No. 1, Persiaran South Key 1, 80150 Johor Bahru, Johor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Sri Tebrau, Johor Bahru', 'Johor', 'No. 9, Jalan Keris, Taman Sri Tebrau, 80050 Johor Bahru, Johor', 'Mon - Sun: 8:30am - 8:00pm'],
        ['CARiNG Pharmacy', 'Taman Pelangi, Johor Bahru', 'Johor', '47, Jalan Perang, Taman Pelangi, 80400 Johor Bahru, Johor', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'AEON Bukit Indah, Iskandar Puteri', 'Johor', 'Lot S51, 2nd Floor, AEON Mall Bukit Indah, No. 8, Jalan Indah 15/2, Bukit Indah, 81200 Iskandar Puteri, Johor', 'Sun - Thurs & PH: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Indahpura, Kulai', 'Johor', 'No. 237, Jalan Kenanga 29/8, Indahpura, 81000 Kulai, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'SKS Mart, Skudai', 'Johor', 'Lot KB 07, 08, 09, 10, Pasar Raya SKS, Pusat Komersial Industri Jaya, Jalan Hang Lekir, Taman Industri Jaya, 81300 Skudai, Johor', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Taman Eko Botani, Iskandar Puteri', 'Johor', '18, Jalan Eko Botani 3/2, Taman Eko Botani, 79100 Iskandar Puteri, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Permas Jaya, Masai', 'Johor', '29, Jalan Permas 10/2, Taman Permas Jaya, Bandar Permas Jaya, 81750 Masai, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Johor Jaya, Johor Bahru', 'Johor', '82, G Floor, Jalan Dedap 4, Taman Johor Jaya, 81100 Johor Bahru, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Jalan Manggis, Kluang', 'Johor', 'No. 11A, G Floor, Jalan Manggis, 86000 Kluang, Johor', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Wisma Centrepoint, Segamat', 'Johor', '148 & 149, G Floor, Wisma Centrepoint, Jalan Dato Sia Her Yam, 85000 Segamat, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Pusat Perniagaan Kota Emas, Pontian', 'Johor', '6, Jalan Emas 5, Pusat Perniagaan Kota Emas, 82000 Pontian, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Toppen Shopping Centre, Johor Bahru', 'Johor', 'L2.111, Level 2, Kompleks Toppen, No. 33A, Jalan Harmonium, Taman Desa Tebrau, 81100 Johor Bahru, Johor', '"Mon - Thu: 10:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Jalan Pesta, Muar', 'Johor', '29-29, G Floor, Jalan Pesta 1/2, Taman Kampung Kenanga Tun Dr Ismail, 84000 Muar, Johor', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Taman Daya, Johor Bahru', 'Johor', '87, Jalan Sagu 18, Taman Daya, 81100 Johor Bahru, Johor', '"Wed - Mon: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Setia Tropika, Johor Bahru', 'Johor', '39, Jalan Setia Tropika 1/15, Taman Setia Tropika, 81200 Johor Bahru, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Old Street, Batu Pahat', 'Johor', '1, Jalan Rotan Kong, Taman Sri Jaya, 83000 Batu Pahat, Johor', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Bukit Perdana, Batu Pahat', 'Johor', '82 & 84, Jalan Susur Perdana Tengah, Taman Bukit Perdana 2, 83000 Batu Pahat, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Kulai Utama, Johor', 'Johor', '12, Jalan Anggerik 1, Taman Kulai Utama, 81000 Kulai, Johor', 'Mon - Sun: 9:00am - 9:00pm'],
      ],
      'Kedah': [
        ['Georgetown Pharmacy', 'Pekan Simpang Kuala, Alor Setar', 'Kedah', '151 & 152, Jalan PSK 3, Pekan Simpang Kuala, 05400 Alor Setar, Kedah', 'Mon - Sun: 8:30am - 9:00pm'],
        ['Georgetown Pharmacy', 'Lagenda Heights, Sungai Petani', 'Kedah', '198 & 199, G Floor, Jalan Lagenda 7, Lagenda Heights, 08000 Sungai Petani, Kedah', 'Mon - Sun: 9:30am - 9:30pm'],
        ['Georgetown Pharmacy', 'Jitra, Kedah', 'Kedah', '25, Jalan KPJ 2/1, Kompleks Perniagaan Jitra 2, 06000 Jitra, Kedah', 'Mon - Sun: 8:30am - 9:30pm'],
        ['Georgetown Pharmacy', 'Mergong, Kedah', 'Kedah', '24A & 25A, Kompleks Perniagaan Utama, Jalan Sultanah Sambungan, 05150 Alor Setar, Kedah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Langkawi, Kedah', 'Kedah', '43 & 45, Jalan Pandak Mayah 5, Pusat Bandar Kuah, 07000 Langkawi, Kedah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Amanjaya Mall, Kedah', 'Kedah', 'Lot No. 11, G Floor, Kompleks Amanjaya, 08000 Sungai Petani, Kedah', 'Sun - Thu: 10:00am - 10:00pm'],
        ['Georgetown Pharmacy', 'Taman Kemuning, Kulim', 'Kedah', '76D & E, Lorong Kemuning 1, Taman Kemuning, 09000 Kulim, Kedah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Dataran Shahab, Alor Setar', 'Kedah', 'No. 11, Dataran Shahab, Jalan Meranti 2, 05300 Alor Setar, Kedah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Taman Sejati Indah, Kedah', 'Kedah', 'No. C63, G Floor, Jalan Permatang Gedong, Taman Sejati Indah, 08000 Sungai Petani, Kedah', 'Mon - Sun: 9:00am - 10:00pm'],
        ['Georgetown Pharmacy', 'Pekan Changloon, Kedah', 'Kedah', 'No. 99A & 100A, Pekan Changloon, 06010 Changloon, Kedah', 'Mon - Sun: 9:00am - 9:30pm'],
      ],
      'Kelantan': [
        ['CARiNG Pharmacy', 'KB Mall, Kota Bharu', 'Kelantan', 'Lot G17 & G18, G Floor, KB Mall, No. 1, Jalan Hamzah, 15050 Kota Bharu, Kelantan', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Kubang Kerian, Kota Bharu', 'Kelantan', 'PT 1673, Tingkat Bawah, Jalan Raja Perempuan Zainab 2, Kubang Kerian, 16150 Kota Bharu, Kelantan', 'Mon - Sun: 9:00am - 9:00pm'],
      ],
      'Kuala Lumpur': [
        ['CARiNG Pharmacy', 'Taman Tenaga, Cheras', 'Kuala Lumpur', '18G, Jalan Sembilang, Taman Tenaga, Cheras, 56000 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Taman Kok Lian, Kuala Lumpur', 'Kuala Lumpur', '26, Jalan Batu Ambar, Off Jalan Ipoh, Taman Kok Lian, 51200 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Sri Petaling, Kuala Lumpur', 'Kuala Lumpur', '22, Jalan Radin Anum 1, Taman Sri Petaling, 57000 Kuala Lumpur', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Kuchai Lama, Kuala Lumpur', 'Kuala Lumpur', '7, Jalan Kuchai Maju 1, Dynasty 2, Off Jalan Kuchai Lama, 58200 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Bangsar Village, Kuala Lumpur', 'Kuala Lumpur', 'Lot G8, G Floor, Bangsar Village, No. 1, Jalan Telawi 1, Bangsar Baru, 59100 Kuala Lumpur', 'Mon - Sun: 9:30am - 10:00pm'],
        ['CARiNG Pharmacy', 'Hartamas Shopping Centre, Kuala Lumpur', 'Kuala Lumpur', 'Lot 3, G Floor, Hartamas Shopping Centre, No. 60, Jalan Sri Hartamas 1, Sri Hartamas, 50480 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Jalan Burhanuddin Helmi, TTDI', 'Kuala Lumpur', '94, Jalan Burhanuddin Helmi, Taman Tun Dr Ismail, 60000 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Pavilion, Kuala Lumpur', 'Kuala Lumpur', 'Lot 1.23, Level 1, Pavilion KL Shopping Mall, No. 168, Jalan Bukit Bintang, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Pudu Pasar, Kuala Lumpur', 'Kuala Lumpur', '30, Jalan Yew, Pudu Pasar, 55100 Kuala Lumpur', 'Mon - Sun: 8:00am - 7:00pm'],
        ['CARiNG Pharmacy', 'Mid Valley Megamall, Kuala Lumpur', 'Kuala Lumpur', 'Lot LG-060A, LG Floor, Mid Valley Megamall, Mid Valley City, Lingkaran Syed Putra, 59200 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Sungei Wang Plaza, Kuala Lumpur', 'Kuala Lumpur', 'Lot G-050 & G-051, G Floor, Sungei Wang Plaza, Jalan Sultan Ismail, Bukit Bintang, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', '1 Mont Kiara, Kuala Lumpur', 'Kuala Lumpur', 'Lot LG-12 & 13, LG Floor, 1 Mont Kiara, No. 1, Jalan Kiara, Mont Kiara, 50480 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Midah, Cheras', 'Kuala Lumpur', '30, Jalan Midah Besar, Taman Midah, Cheras, 56000 Kuala Lumpur', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Taman Bukit Maluri, Kepong', 'Kuala Lumpur', '31, Jalan Burung Jentayu, Taman Bukit Maluri, Kepong, 51100 Kuala Lumpur', 'Mon, Wed - Fri: 12:30pm - 9:30pm'],
        ['CARiNG Pharmacy', 'Berjaya Times Square, Kuala Lumpur', 'Kuala Lumpur', 'Lot LG 32 & 33, LG Floor, Berjaya Times Square, No. 1, Jalan Imbi, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Segar, Cheras', 'Kuala Lumpur', '22, Jalan Manis 4, Taman Segar, Cheras, 56100 Kuala Lumpur', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Prima Setapak, Kuala Lumpur', 'Kuala Lumpur', '19, Jalan Prima Setapak 3, Prima Setapak, 53100 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Avenue K, Kuala Lumpur', 'Kuala Lumpur', 'Lot C-8, Concourse Floor, Avenue K, No. 156, Jalan Ampang, 50450 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Kepong Baru, Kuala Lumpur', 'Kuala Lumpur', '8, Persiaran Mergastua, Kepong Baru, 52100 Kuala Lumpur', 'Mon - Sun: 8:00am - 8:30pm'],
        ['CARiNG Pharmacy', 'Jalan Tun Mohd Fuad 1, TTDI', 'Kuala Lumpur', '24, Jalan Tun Mohd Fuad 1, Taman Tun Dr Ismail, 60000 Kuala Lumpur', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Sunway Putra Mall, Kuala Lumpur', 'Kuala Lumpur', 'LG-1B, LG Floor, Sunway Putra Mall, No. 100, Jalan Putra, 50350 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Jalan Ipoh, Kuala Lumpur', 'Kuala Lumpur', '479, Batu 3, Jalan Ipoh, 51200 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Sunway Velocity Mall, Kuala Lumpur', 'Kuala Lumpur', 'Lot B-05, 06 & 07, Sunway Velocity Mall, Lingkaran SV, Sunway Velocity, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'MyTown, Kuala Lumpur', 'Kuala Lumpur', 'Lot No. B1-073A, Basement 1, MyTown Shopping Centre, No. 6, Jalan Cochrane, Seksyen 90, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'EkoCheras Mall, Cheras', 'Kuala Lumpur', 'Lot LG-42, EkoCheras Mall, No. 693, Batu 5, Jalan Cheras, 56000 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Sentul Raya Boulevard, Kuala Lumpur', 'Kuala Lumpur', 'No. 19, G Floor, Jalan 11/48A, Sentul Raya Boulevard, 51000 Kuala Lumpur', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Ansa, Kuala Lumpur', 'Kuala Lumpur', 'Lot 0.2A, G Floor, No. 101, Jalan Bukit Bintang, 55100 Kuala Lumpur', 'Mon - Thurs: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Lotuss Kepong, Kuala Lumpur', 'Kuala Lumpur', 'Lot G11, G Floor, Lotuss Kepong, No. 3, Jalan 7A/62A, Bandar Menjalara, 52200 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Plaza Damansara, Kuala Lumpur', 'Kuala Lumpur', '67-G, Jalan Medan Setia 1, Plaza Damansara, Damansara Heights, 50490 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Pavilion, Bukit Jalil', 'Kuala Lumpur', 'Lot 1.94.00, Pavilion Bukit Jalil, No. 2, Persiaran Jalil 8, Bandar Bukit Jalil, 57000 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Lakefield, Sungai Besi', 'Kuala Lumpur', '2A-G, Jalan Tasik Utama 10, Medan Niaga Tasik Damai, Sungai Besi, 57000 Kuala Lumpur', 'Mon - Sun: 10:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'The Tropika, Bukit Jalil', 'Kuala Lumpur', 'Lot G-3A, The Tropika Bukit Jalil, No. 2, Jalan Jalil Perkasa 3, Bukit Jalil, 57000 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Bandar Sri Permaisuri, Cheras', 'Kuala Lumpur', '31, Ground Floor, Jalan Sri Permaisuri 9, Bandar Sri Permaisuri, 56000 Cheras, Kuala Lumpur', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Verve Shops Mont Kiara, KL', 'Kuala Lumpur', 'Lot G-05, G Floor, Verve Shops Mont Kiara, No. 8, Jalan Kiara 5, 50480 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Cheras, KL', 'Kuala Lumpur', '30, G Floor, Jalan Kaskas 3, Taman Cheras, 56100 Kuala Lumpur', 'Mon - Sun: 7:00am - 7:00pm'],
      ],
      'Melaka': [
        ['CARiNG Pharmacy', 'AEON Bandaraya Melaka, Melaka', 'Melaka', 'Lot G08, G Floor, AEON Bandaraya Melaka, No. 2, Jalan Lagenda, Taman I-Lagenda, 75400 Melaka', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Kota Laksamana, Melaka', 'Melaka', 'No. 49, Jalan KL 3/8, Taman Kota Laksamana, Seksyen 3, 75200 Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Bukit Beruang, Melaka', 'Melaka', 'No. 756A, Jalan Emas 9, Taman Kerjasama, Hang Tuah Jaya, Bukit Beruang, 75450 Ayer Keroh, Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Bandar Baru Jasin III, Melaka', 'Melaka', 'JC 4040, Jalan Demang Haji Abu, Bandar Baru Jasin III, 77000 Jasin, Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Ayer Keroh Heights, Melaka', 'Melaka', '9, Lorong Setia 1, Taman Ayer Keroh Heights, 75450 Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Malim Jaya, Melaka', 'Melaka', '5, Jalan Rahmat 2, Taman Malim Jaya, 75250 Melaka, Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Cheng Baru, Melaka', 'Melaka', '21, Jalan Seri 7, Taman Cheng Baru, 75250 Melaka, Melaka', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Wellings Pharmacy', 'Jalan Melaka Raya, Melaka', 'Melaka', '305, Jalan Melaka Raya 1, Taman Melaka Raya, 75000 Melaka', 'Mon - Sun: 8:30am - 8:30pm'],
      ],
      'Negeri Sembilan': [
        ['CARiNG Pharmacy', 'Taipan Senawang, Seremban', 'Negeri Sembilan', '161-G, Taipan Senawang, Jalan Komersial Senawang 1, Taman Komersial Senawang, 70450 Seremban, Negeri Sembilan', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Jalan Mewah, Bahau', 'Negeri Sembilan', '111, G Floor, Wisma Pahlawan, Jalan Mewah, 72100 Bahau, Negeri Sembilan', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Centrio, Seremban 2', 'Negeri Sembilan', '64, Jalan S2 B21, Pusat Dagangan Seremban 2, 70300 Seremban, Negeri Sembilan', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Seremban Gateway, Seremban', 'Negeri Sembilan', 'Lot 3-G, Gerbang Seremban, Off Jalan Sungai Ujong, 70200 Seremban, Negeri Sembilan', 'Mon - Sun: 10:00am - 10:30pm'],
      ],
      'Pahang': [
        ['CARiNG Pharmacy', 'Jalan Tun Ismail, Kuantan', 'Pahang', 'No. 47, Jalan Tun Ismail, 25200 Kuantan, Pahang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Bandar Indera Mahkota, Kuantan', 'Pahang', 'B168, Lorong IM 8/33, Bandar Indera Mahkota, 25200 Kuantan, Pahang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Jalan Chui Yin, Bentong', 'Pahang', 'P-14, Jalan Chui Yin, 28700 Bentong, Pahang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Jalan Bunga Raya, Mentakab', 'Pahang', '23 & 25, Jalan Bunga Raya, 28400 Mentakab, Pahang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'First World Hotel, Genting Highlands', 'Pahang', 'Lot No. T2A-10 & 11, Level 2, First World Hotel, 69000 Genting Highlands, Pahang', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Pandan Damai, Kuantan', 'Pahang', 'B18-20, Tingkat Bawah, Lorong Pandan Damai 2/201, Taman Pandan Damai, 25150 Kuantan, Pahang', 'Mon - Sun: 9:00am - 9:00pm'],
      ],
      'Penang': [
        ['CARiNG Pharmacy', 'Queensbay Mall, Bayan Lepas', 'Penang', 'Lot LG 19 (Postal LG-12B), Queensbay Mall, No. 100, Persiaran Bayan Indah, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 10:30am - 10:30pm'],
        ['CARiNG Pharmacy', 'Gurney Paragon, George Town', 'Penang', 'Lot 163D-LG-01B & 02, Gurney Paragon, Persiaran Gurney, 10250 George Town, Pulau Pinang', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Mydin Mall Bukit Mertajam, Penang', 'Penang', 'Lot G9, Mydin Mall Bukit Mertajam, Mukim 06, Jalan Baru, 13600 Seberang Perai Tengah, Pulau Pinang', 'Mon - Fri: 12:00pm - 10:00pm'],
        ['Georgetown Pharmacy', 'Pesta Sungai Nibong, Bayan Lepas', 'Penang', '72, Jalan Aziz Ibrahim, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Sunshine Bayan Baru, Bayan Lepas', 'Penang', '52, Jalan Mahsuri, Bayan Baru, 11950 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Wellings Pharmacy', 'Bellisa Row, George Town', 'Penang', '368-1-12, 12A & 14, Bellisa Row, Jalan Burma, 10350 George Town, Pulau Pinang', 'Mon - Sun: 8:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Sungai Ara, Bayan Lepas', 'Penang', '306-D-1, Jalan Dato Ismail Hashim, Desaria, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Jelutong, Penang', 'Penang', '336F-G, Jalan Perak, 10150 Jelutong, Pulau Pinang', 'Mon - Sun: 8:30am - 8:45pm'],
        ['Georgetown Pharmacy', 'Farlim Square, Ayer Itam', 'Penang', '62-1 & 62A-1, Farlim Square, Jalan Pisang Berangan, Bandar Baru Air Itam, 11500 Ayer Itam, Pulau Pinang', 'Mon - Sun: 9:00am - 8:45pm'],
        ['Georgetown Pharmacy', 'Balik Pulau, Penang', 'Penang', '104, Jalan Besar, 11000 Balik Pulau, Pulau Pinang', 'Mon - Sun: 8:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Summerton, Penang', 'Penang', '110-01-03, Persiaran Bayan Indah, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:30pm'],
        ['Georgetown Pharmacy', 'Alma, Bukit Mertajam', 'Penang', '2381, Jalan Rozhan, Taman Impian, 14000 Bukit Mertajam, Pulau Pinang', 'Mon - Sun: 8:30am - 9:00pm'],
        ['Georgetown Pharmacy', 'Jalan Ong Yi How, Butterworth', 'Penang', '1, Jalan Ong Yi How, 13400 Butterworth, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Wellings Pharmacy', 'Redrock Hotel, Penang', 'Penang', '202, 202-A, Redrock Hotel, Jalan Macalister, 10400 George Town, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Macalister Road, Penang', 'Penang', '132-GF & 134-GF, Jalan Macalister, 10400 George Town, Pulau Pinang', 'Mon - Sun: 9:00am - 8:45pm'],
        ['Wellings Pharmacy', '(Mansion) Jalan Sultan Ahmad Shah, George Town', 'Penang', '67, Jalan Sultan Ahmad Shah, 10350 George Town, Pulau Pinang', 'Mon - Sun: 8:30am - 8:30pm'],
        ['Georgetown Pharmacy', 'Seberang Jaya, Seberang Perai', 'Penang', '19, G Floor, Jalan Todak 4, Pusat Bandar Seberang Jaya Perai, 13700 Seberang Perai, Pulau Pinang', 'Mon - Sun: 8:30am - 9:00pm'],
        ['Georgetown Pharmacy', 'Nibong Tebal, Penang', 'Penang', '1325, Jalan Atas, 14300 Nibong Tebal, Pulau Pinang', 'Mon - Sun: 8:30am - 9:30pm'],
        ['Georgetown Pharmacy', 'Golden Triangle, Bayan Lepas', 'Penang', '35-1-30, Golden Triangle 2 Commercial Square, Jalan Dato Ismail Hashim, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Macallum, George Town', 'Penang', 'No. 28, Gat Lebuh Macallum, 10300 George Town, Pulau Pinang', 'Mon - Sun: 8:00am - 8:45pm'],
        ['Georgetown Pharmacy', 'Taman Inderawasih, Perai', 'Penang', '157, Medan Kikik 1, Taman Inderawasih, 13600 Perai, Pulau Pinang', 'Mon - Sun: 8:00am - 8:00pm'],
        ['Georgetown Pharmacy', 'Taman Mutiara, Bukit Mertajam', 'Penang', '21, G Floor, Jalan Tembikai, Taman Mutiara, 14000 Bukit Mertajam, Pulau Pinang', 'Mon - Sun: 9:00am - 6:00pm'],
        ['Georgetown Pharmacy', 'Simpang Ampat, Penang', 'Penang', '167 & 169, G Floor, Lorong 22/SS1, Bandar Tasek Mutiara, 14120 Simpang Ampat, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Chai Leng Park, Perai', 'Penang', 'No. 39 & 41, Lebuh Kurau 1, Chai Leng Park, 13700 Perai, Pulau Pinang', 'Mon - Sun: 8:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Jalan Tan Sri Teh Ewe Lim, George Town', 'Penang', '129F, Jalan Tan Sri Teh Ewe Lim, 11600 George Town, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Georgetown Pharmacy', 'Amarene, Bayan Lepas', 'Penang', '3-1-06 & 3-1-07, The Amarene, Jalan Tun Dr Awang, 11900 Bayan Lepas, Pulau Pinang', 'Mon - Sun: 9:00am - 9:00pm'],
        ['Wellings Pharmacy', 'One Pacific, George Town', 'Penang', 'One Part of Ground Floor 177, Jalan Macalister, 10400 George Town, Pulau Pinang', 'Mon - Sun: 8:30am - 8:30pm'],
        ['Georgetown Pharmacy', 'Kampung Baru, Bukit Mertajam', 'Penang', 'Ground Floor of No. 2829, Jalan Kampung Baru, Taman Tampoi, 14000 Bukit Mertajam, Pulau Pinang', 'Mon - Sun: 8:30am - 9:00pm'],
      ],
      'Perak': [
        ['CARiNG Pharmacy', 'AEON Ipoh Station 18, Ipoh', 'Perak', 'Lot G02, G Floor, AEON Ipoh Station 18 Shopping Centre, No. 2, Susuran Stesen 18, Station 18, 31650 Ipoh, Perak', 'Sun - Thurs: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Ipoh Parade, Ipoh', 'Perak', 'Lot G38, G Floor, Ipoh Parade, No. 105, Jalan Sultan Abdul Jalil, Greentown, 30450 Ipoh, Perak', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Ipoh Garden, Ipoh', 'Perak', '121, G Floor, Jalan Datuk Lau Pak Khuan, Ipoh Garden, 31400 Ipoh, Perak', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Gunung Rapat, Ipoh', 'Perak', 'No. 691, Jalan Gunung Rapat, Gunung Rapat, 31350 Ipoh, Perak Malaysia', 'Mon - Sun: 08:00am - 08:00pm'],
        ['CARiNG Pharmacy', 'Bercham, Ipoh', 'Perak', '7, Persiaran Bercham Selatan 10, Taman Desa Kencana, 31400 Ipoh, Perak', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Pinji, Ipoh', 'Perak', '2, Jalan Centrepoint 1, Jalan Bharu off Jalan Tokong, 31650 Ipoh, Perak', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Taman Pertama, Ipoh', 'Perak', '57, Lorong Taman 11, Taman Pertama, 30100 Ipoh, Perak', 'Mon - Sun: 8:00am - 8:00pm'],
        ['Georgetown Pharmacy', 'Jalan Taiping, Parit Buntar', 'Perak', '29, Jalan Taiping, 34200 Parit Buntar, Perak', 'Mon - Sun: 9:00am - 9:00pm'],
      ],
      'Perlis': [
        ['Georgetown Pharmacy', 'Arau, Perlis', 'Perlis', '4A & 6A, Jalan Tiga, Kompleks Tok Arau, 02600 Arau, Perlis', 'Mon - Sun: 9:00am - 9:30pm'],
      ],
      'Sabah': [
        ['CARiNG Pharmacy', 'Centre Point Sabah, Kota Kinabalu', 'Sabah', 'Lot L9A, Basement Floor, Centre Point Sabah, No. 1, Jalan Centre Point, 88000 Kota Kinabalu, Sabah', 'Mon - Sun: 10:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Damai Plaza, Kota Kinabalu', 'Sabah', 'Lot No. 39 & 40, G Floor, Block D, Damai Plaza, Phase 1, Jalan Damai, 88300 Kota Kinabalu, Sabah', 'Mon - Sun: 8:00am - 8:30pm'],
        ['CARiNG Pharmacy', 'Suria Sabah, Kota Kinabalu', 'Sabah', 'Lot Part of B-68 (iv), Lower Ground Floor, Suria Sabah Shopping Mall, No. 1, Jalan Tun Fuad Stephens, 88000 Kota Kinabalu, Sabah', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Imago Shopping Mall, Kota Kinabalu', 'Sabah', 'Lot B-32B, Basement Floor, Imago KK Times Square, Off Coastal Highway, 88100 Kota Kinabalu, Sabah', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Penampang, Kota Kinabalu', 'Sabah', 'Lot 8, Blok 21, Bandar Koidupan Penampang, 88200 Kota Kinabalu, Sabah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Lintas Plaza, Kota Kinabalu', 'Sabah', 'Lot 3-0, G Floor, Lintas Plaza, Jalan Lintas, 88300 Kota Kinabalu, Sabah', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'EG Mall Inanam, Kota Kinabalu', 'Sabah', 'G15, EG Mall Inanam, No. 26, Taman Perindustrian Bukit Emas, Mile 7.5, 88450 Kota Kinabalu, Sabah', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', '88 Market Place, Kota Kinabalu', 'Sabah', 'Lot No. 6, Block C, Lorong Pusat Komersil 88/2, 88 Market Place, 88300 Kota Kinabalu, Sabah', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Bandar Pasaraya, Sandakan', 'Sabah', 'Lot 91, Block L, Bandar Pasaraya, Batu 4, Jalan Utara, 90000 Sandakan, Sabah', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Bandar Indah, Mile 4, Sandakan', 'Sabah', 'Lot 5, Block 1, Ground Floor, Bandar Indah, Mile 4, 90000 Sandakan, Sabah', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Utama Place, Batu 6, Sandakan', 'Sabah', 'Lot 31, Ground Floor, Block B4, Utama Place, 2 & 5, Jalan Utara, Batu 6, 90000 Sandakan, Sabah', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Sandakan Town Centre, Sandakan', 'Sabah', 'Lot 7A, Block 19, Sandakan Town Centre, Lebuh Dua, 90000 Sandakan, Sabah', 'Mon - Sun: 8:00am - 5:00pm'],
      ],
      'Sarawak': [
        ['CARiNG Pharmacy', 'Emart Matang, Kuching', 'Sarawak', 'Arcade Shop S17, Lot 9808, Section KTLD, Emart Commercial Centre, 4th Mile, Jalan Matang, 93050 Kuching, Sarawak', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Summer Mall, Kota Samarahan', 'Sarawak', 'Ground Floor, G63-G64 & G69-G70, The Summer Shopping Mall, Lot 1396-1403 & Lot 108, Block 1, Samarahan District, Jalan Dato Mohd Musa, 94300 Kota Samarahan, Sarawak', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Emart One Stop Batu Kawa, Kuching', 'Sarawak', 'Shop 72 of Ground Floor, Emart One Stop Shopping Mall, Lot 6369, Block 225, KNLD, 4th Mile, Batu Kawa, 93250 Kuching, Sarawak', 'Mon - Sun: 9:30am - 10:00pm'],
        ['CARiNG Pharmacy', 'Song Plaza, Kuching', 'Sarawak', 'Ground Floor, No. 12 & 13, Lot 12174, Block 16, Kuching Central Land District, Jalan Song, Song Plaza, 93350 Kuching, Sarawak', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Batu Kawa, Kuching', 'Sarawak', 'Ground Floor, No. 52 (Sub Lot 51), Lot 6423, Block 225, KNLD, Emart Batu Kawa Commercial Centre, Jalan Batu Kawa, 93250 Kuching, Sarawak', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Kota Sentosa 7th Mile, Kuching', 'Sarawak', 'Ground Floor, No. 1, Lot 651, Block 233, KNLD, 7th Mile Bazaar, Penrissen Road, 93250 Kuching, Sarawak', 'Mon - Sun: 7:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Aeroville Mall, Kuching', 'Sarawak', 'Ground Floor, Commercial Lot 2-Lot A, Aeroville Mall Kuching, Lot 19923, Block 11, MTLD, Jalan Stutong Baru, 93350 Kuching, Sarawak', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', '3rd Mile Bazaar, Kuching', 'Sarawak', 'Ground Floor, No. 4, 3rd Mile Bazaar, 93200 Kuching, Sarawak', 'Mon - Sun: 7:00am - 7:00pm'],
        ['CARiNG Pharmacy', 'Serian Piazza Mall, Serian', 'Sarawak', 'Ground Floor, Lot No. 4, Sublot 113, Serian Piazza, Jalan Datuk Michael Ben, 94700 Serian, Sarawak', 'Sun - Thu: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Emart Tabuan Jaya, Kuching', 'Sarawak', 'G23 & G24, Emart Tabuan Jaya, No. 1, Tabuan Tranquility Commercial Centre, Jalan Canna, 93350 Kuching, Sarawak', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Jalan Satok, Kuching', 'Sarawak', 'Ground Floor, No. 155C, Lot 235, Section 10 KTLD, Jalan Satok, 93400 Kuching, Sarawak', 'Mon - Sun: 8:30am - 8:30pm'],
        ['JOM Pharmacy', 'Vivacity Megamall, Kuching (JOM)', 'Sarawak', 'Parcel No. LG-010 Level Lower Ground Floor of Vivacity Megamall, Jalan Wan Alwi, 93350 Kuching, Sarawak', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Vivacity Megamall, Kuching', 'Sarawak', 'G-044A, G Floor, Vivacity Megamall, Jalan Wan Alwi, Taman Supreme, 93350 Kuching, Sarawak', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Saradise Kuching', 'Sarawak', 'Ground Floor, Lot 5, Block A1, Saradise Kuching, 93350 Kuching, Sarawak', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Farley Mall, Kuching', 'Sarawak', 'Ground Floor, Lot A16, Farley Kuching, Lot 4547, Block 233, KNLD, 93250 Kuching, Sarawak', 'Mon - Sun: 9:00am - 9:30pm'],
      ],
      'Selangor': [
        ['CARiNG Pharmacy', 'Taman Muda, Cheras', 'Selangor', '28, Jalan Bunga Tanjung 9, Taman Muda, Cheras, 56100 Kuala Lumpur', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Section 17, Petaling Jaya', 'Selangor', '1076, Jalan 17/29, Happy Garden, Section 17, 46400 Petaling Jaya, Selangor', 'Mon - Sun: 8:00am - 8:30pm'],
        ['CARiNG Pharmacy', 'SEA Park, Petaling Jaya', 'Selangor', '31, Jalan 21/17, SEA Park, 46300 Petaling Jaya, Selangor', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Taman Kinrara, Puchong', 'Selangor', '19A, Jalan TK 1/11A, Section 1, Taman Kinrara, 47180 Puchong, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'SS2, Petaling Jaya', 'Selangor', '36, Jalan SS 2/67, 47300 Petaling Jaya, Selangor', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Eng Ann, Klang', 'Selangor', '18 & 20, Jalan Kasawari 8, Taman Eng Ann, 41150 Klang, Selangor', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Section 14, Petaling Jaya', 'Selangor', '6B-1, Jalan 14/20, Section 14, 46100 Petaling Jaya, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'USJ 2, Subang Jaya', 'Selangor', '15, Jalan USJ 2/2C, 47600 Subang Jaya, Selangor', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', '1 Utama, Petaling Jaya', 'Selangor', 'B6 & B6A, Basement 1, 1 Utama Shopping Centre, Lebuh Bandar Utama, 47800 Petaling Jaya, Selangor', 'Sun - Thu: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Old Town, Petaling Jaya', 'Selangor', 'I-19, Jalan Pasar 1/21, Section 1, 46000 Petaling Jaya, Selangor', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Mid Point, Kuala Lumpur', 'Selangor', 'Lot G9 & G10, Mid Point Shopping Centre, Jalan Pandan Indah 1/25, Taman Pandan Indah, 55100 Kuala Lumpur', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Kelana Jaya, Petaling Jaya', 'Selangor', 'F-G-03A, Glomac Square, Jalan SS 6/5B, Kelana Jaya, 47301 Petaling Jaya, Selangor', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Subang Parade, Subang Jaya', 'Selangor', 'Lot 20 E-H, LG Floor, Subang Parade, No. 5, Jalan SS 16/1, 47500 Subang Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Sunway Pyramid, Petaling Jaya', 'Selangor', 'Lot LG 2.77, LG2 Floor, Sunway Pyramid, No. 3, Jalan PJS 11/15, Bandar Sunway, 46150 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'AEON Bukit Tinggi, Klang', 'Selangor', 'Lot S21, 2nd Floor, AEON Bukit Tinggi Shopping Complex, No. 1, Persiaran Batu Nilam 1/KS6, Bandar Bukit Tinggi 2, 41200 Klang, Selangor', 'Sun - Thurs: 10:00am - 10:00pm '],
        ['CARiNG Pharmacy', 'Taipan USJ 10, Subang Jaya', 'Selangor', '17, Jalan USJ 10/1E, 47620 Subang Jaya, Selangor', 'Mon - Sat: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'The Mines, Seri Kembangan', 'Selangor', 'Lot L1-50, Level 1, The Mines, Jalan Dulang, Mines Shopping City, 43300 Seri Kembangan, Selangor', 'Mon - Thu: 11:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'IOI Mall, Puchong', 'Selangor', 'G00B5, G Floor, IOI Mall, Batu 9 Jalan Puchong, Bandar Puchong Jaya, 47170 Puchong, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'SS15, Subang Jaya', 'Selangor', 'C6, Jalan SS 15/4D, SS15, 47500 Subang Jaya, Selangor', 'Mon - Sun: 8:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Sunway Giza, Kota Damansara', 'Selangor', 'Lot B-10-G, Sunway Giza, No. 2, Jalan PJU 5/14, Sunway Damansara, Kota Damansara, 47810 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Empire Gallery, Subang Jaya', 'Selangor', 'Lot LG 16, LG Floor, Empire Shopping Gallery, Jalan SS 16/1, SS 16, 47500 Subang Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Prima Sri Gombak, Batu Caves', 'Selangor', '61, Jalan Prima SG 3/1, Prima Sri Gombak, 68100 Batu Caves, Selangor', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Jalan Besar, Seri Kembangan', 'Selangor', '5A, Jalan Besar, 43300 Seri Kembangan, Selangor', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Setia Walk, Puchong', 'Selangor', 'F-02-G, Block F, SetiaWalk, Persiaran Wawasan, Pusat Bandar Puchong, 47160 Puchong, Selangor', 'Mon - Fri : 12:00pm - 9:00pm'],
        ['CARiNG Pharmacy', 'Plaza Shah Alam, Shah Alam', 'Selangor', 'Lot LG-2A, LG Floor, Plaza Shah Alam, No. 2, Jalan Tengku Ampuan Zabedah E9/E, Section 9, 40100 Shah Alam, Selangor', 'Mon - Sat: 12:00pm - 9:30pm'],
        ['CARiNG Pharmacy', 'AEON Anggun Rawang, Rawang', 'Selangor', 'Lot G6, Kompleks Beli Belah AEON Anggun Rawang, No. 1, Persiaran Anggun, 48000 Rawang, Selangor', 'Sun - Thurs: 10:00am - 10:00pm '],
        ['CARiNG Pharmacy', 'Bandar Sri Damansara, Kuala Lumpur', 'Selangor', '15, Jalan Tanjung SD 13/1, Bandar Sri Damansara, 52200 Kuala Lumpur', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Paradigm Mall, Petaling Jaya', 'Selangor', 'Lot LG 45, LG Floor, Paradigm Mall, No. 1, Jalan SS 7/26A, Kelana Jaya, 47301 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Setia City Mall, Shah Alam', 'Selangor', 'Lot LG 62, LG Floor, Setia City Mall, No. 7, Persiaran Setia Dagang, Seksyen U13, Bandar Setia Alam, 40170 Shah Alam, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Segar Perdana, Cheras', 'Selangor', 'G-11A, Jalan SP2, Taman Segar Perdana, 43200 Cheras, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Melawati, Kuala Lumpur', 'Selangor', 'Lot 9179, G Floor, Jalan Negara, Taman Melawati, 53100 Kuala Lumpur', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Plaza Jelutong, Shah Alam', 'Selangor', 'Lot 2, GL 2, No. 1, Plaza Jelutong, Persiaran Gerbang Utama, Bukit Jelutong, 40150 Shah Alam, Selangor', 'Mon - Sun: 9:30am - 10:00pm'],
        ['CARiNG Pharmacy', 'Jalan Raja Haroun, Kajang', 'Selangor', '12, Jalan Raja Haroun, 43000 Kajang, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Pandan Jaya, Kuala Lumpur', 'Selangor', '45, Jalan Pandan 3/3, Pandan Jaya, 55100 Kuala Lumpur', 'Mon - Sun: 8:30am - 9:00pm'],
        ['CARiNG Pharmacy', 'Desa Aman Puri, Kepong', 'Selangor', '1-33A, Block D, Jalan Desa 1/3, Desa Aman Puri, Kepong, 52100 Kuala Lumpur', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'The Main Place, Subang Jaya', 'Selangor', 'Lot LG-01 & 02, LG Floor, The Main Place, Jalan USJ 21/10, Persiaran Kewajipan, 47630 Subang Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'IOI City Mall, Putrajaya', 'Selangor', 'Lot LG 18, IOI City Mall, Lebuh IRC, IOI Resort City, 62502 Putrajaya, Sepang, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Atria Shopping Gallery, Petaling Jaya', 'Selangor', 'C02, Concourse Floor, Atria Shopping Gallery, Atria Damansara, Jalan SS 22/23, Damansara Jaya, 47400 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Megah, Petaling Jaya', 'Selangor', '29, Jalan SS 24/8, Taman Megah, 47301 Petaling Jaya, Selangor', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Da:men USJ, Subang Jaya', 'Selangor', 'Lot LG-25, Da:men USJ, No. 1, Persiaran Kewajipan, USJ 1, 47600 Subang Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Aeon Mall Shah Alam, Shah Alam', 'Selangor', 'Lot G-61, G Floor, AEON Mall Shah Alam, No. 1, Jalan Akuatik 13/64, Sekysen 13, 40100 Shah Alam, Selangor', 'Sun - Thurs: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Bandar Mahkota Cheras, Cheras', 'Selangor', '66, Jalan Temenggung 19/9, Bandar Mahkota Cheras, 43200 Cheras, Selangor', 'Mon - Fri: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Citta Mall, Ara Damansara', 'Selangor', 'Lot G-23A, Citta Mall, No. 1, Jalan PJU 1A/48, PJU 1A, Ara Damansara, 47301 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Jalan Besar Ampang, Ampang', 'Selangor', '67, Jalan Besar Ampang, 68000 Ampang, Selangor', 'Mon - Sun: 8:00am - 8:00pm'],
        ['CARiNG Pharmacy', 'Tamarind Square, Cyberjaya', 'Selangor', 'Lot G-15, G Floor, Tamarind Square, Persiaran Multimedia, Cyber 10, 63000 Cyberjaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Central i-City, Shah Alam', 'Selangor', 'Lot LG-46 & LG-47, LG Floor, Sentral i-City, Plot 1, Persiaran Multimedia, Seksyen 7, 40000 Shah Alam, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Jati, Rawang', 'Selangor', 'No. 5, Jalan 1B, Batu 17, Taman Jati, 48000 Rawang, Selangor', 'Mon - Sun: 9:00am - 9:30pm'],
        ['CARiNG Pharmacy', 'Quayside Mall, Kuala Langat', 'Selangor', 'Lot G-21, Kompleks Perniagaan Gamuda, Persiaran Freesia, Gamuda Kemuning 25.7, 42500 Telok Panglima Garang, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Damansara Perdana, Petaling Jaya', 'Selangor', 'No. 15-1, G Floor, Jalan PJU 8/5i, Perdana Business Central, Bandar Damansara Perdana, 47820 Petaling Jaya, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Eco Grandeur, Puncak Alam', 'Selangor', 'No. 17, Ruang Niaga Eco Grandeur, Persiaran Mokhtar Dahari, 42300 Bandar Puncak Alam, Selangor', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Jalan Radin, Sekinchan', 'Selangor', '149A, Jalan Radin, 45400 Sekinchan, Selangor', 'Mon - Sun: 8:30am - 8:30pm'],
        ['CARiNG Pharmacy', 'Hero Market, Bandar Puteri Puchong', 'Selangor', 'Lot G-08, Hero Market Bandar Puteri, No. 5452, 5454, 5456, Jalan Puteri 1/1, Bandar Puteri, 47100 Puchong, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Taman Meranti Jaya, Puchong', 'Selangor', '37-G, Block C, Tanming Boulevard, Jalan Meranti Jaya 3/1, Taman Meranti Jaya, 47120 Puchong, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Taman Bendahara, Kuala Selangor', 'Selangor', '28, Jalan Bendahara 1/1, Taman Bendahara, 45000 Kuala Selangor, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'The Starling Mall, Petaling Jaya', 'Selangor', 'Lot B-009, Basement 1, The Starling Mall, No. 6, Jalan SS21/37, Damansara Uptown, 47400 Petaling Jaya, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Bandar Puchong Utama, Puchong', 'Selangor', '41, Jalan BPU 2, Bandar Puchong Utama, 47100 Puchong, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Setia EcoHill Mall, Semenyih', 'Selangor', 'Lot L1-05, Level 1, Ecohill Mall, Jalan Ecohill 1/2, Setia Ecohill, 43500 Semenyih, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Olive Hill, Seri Kembangan', 'Selangor', 'Lot 39, LG Floor, Jalan BS 1/3, Pusat Perniagaan Olive Hill, Taman Bukit Serdang, Seksyen 1, 43300 Seri Kembangan, Selangor', '"Mon - Fri: 9:00am - 9:00pm'],
        ['CARiNG Pharmacy', 'Centrepoint, Petaling Jaya', 'Selangor', 'Lot G22, G23 & G24, G Floor, Centrepoint, No. 3, Lebuh Bandar Utama, Bandar Utama, 47800 Petaling Jaya, Selangor', 'Mon - Sun: 9:30am - 9:30pm'],
        ['CARiNG Pharmacy', 'Gamuda Walk, Kota Kemuning', 'Selangor', 'Lot G-22, Gamuda Walk, Wisma Biz Gamuda, No. 12, Persiaran Anggerik Vanilla, Kota Kemuning, Seksyen 31, 40460 Shah Alam, Selangor', 'Mon - Sun: 10:00am - 10:00pm'],
        ['CARiNG Pharmacy', 'Equine Park, Seri Kembangan', 'Selangor', 'Lot 13, G Floor, Jalan Equine 1C, Taman Equine, 43300 Seri Kembangan, Selangor', 'Mon - Sun: 9:00am - 9:00pm'],
      ],
      'Terengganu': [
        ['CARiNG Pharmacy', 'KTCC Mall, Kuala Terengganu', 'Terengganu', 'G-33A, Ground Floor, KTCC Mall, Jalan Sultan Zainal Abidin, Muara Selatan, 20000 Kuala Terengganu, Terengganu', 'Mon - Sun: 10:00am - 10:00pm']
      ]
    }
};